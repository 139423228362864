import React, { useEffect, useState, useRef } from 'react'
import { GetList, GetListAsync, ListResultHandler, Trailer, TrailerAsync } from '../APIS/apis'
import { getHome } from '../APIS/home-api'
import SlickSlider from '../SlickSlider/SlickSlider'
import { ThreeCircles } from 'react-loader-spinner'
import { BsFillPlayFill } from 'react-icons/bs';
import { AiFillCaretDown } from 'react-icons/ai';
import { AiFillCaretUp } from 'react-icons/ai';

import './home.css'
const lookup = require('country-code-lookup')


function Home({ seturl, userCountry, setLoadMoreArray, setPages, setPagesNo }) {

    const homeList = [`https://api.themoviedb.org/3/discover/movie?api_key=ab69a8dae367fa71b35c1ebcc00bb16a&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres=18&with_watch_providers=undefined&watch_region=${userCountry}&with_watch_monetization_types=flatrate`,
    `https://api.themoviedb.org/3/discover/movie?api_key=ab69a8dae367fa71b35c1ebcc00bb16a&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_keywords=trending&watch_region=${userCountry}&with_watch_monetization_types=flatrate`,
    `https://api.themoviedb.org/3/discover/movie?api_key=ab69a8dae367fa71b35c1ebcc00bb16a&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres=35&watch_region=${userCountry}&with_watch_monetization_types=flatrate`,
    `https://api.themoviedb.org/3/discover/movie?api_key=ab69a8dae367fa71b35c1ebcc00bb16a&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres=16&watch_region=${userCountry}&with_watch_monetization_types=flatrate`,
    `https://api.themoviedb.org/3/discover/tv?api_key=ab69a8dae367fa71b35c1ebcc00bb16a&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres=99&watch_region=${userCountry}&with_watch_monetization_types=flatrate`,
    `https://api.themoviedb.org/3/discover/tv?api_key=ab69a8dae367fa71b35c1ebcc00bb16a&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres=35&watch_region=${userCountry}&with_watch_monetization_types=flatrate`,
    `https://api.themoviedb.org/3/discover/tv?api_key=ab69a8dae367fa71b35c1ebcc00bb16a&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres=9648&with_watch_providers=undefined&watch_region=${userCountry}&with_watch_monetization_types=flatrate`,
    `https://api.themoviedb.org/3/discover/tv?api_key=ab69a8dae367fa71b35c1ebcc00bb16a&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_keywords=trending&watch_region=${userCountry}&with_watch_monetization_types=flatrate`,]

    const [homeLists, setHomeLists] = useState([])
    const [trailerId, setTrailerId] = useState('')
    const [trailerActive, setTrailerActive] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false);
    const [loader, setLoader] = useState(true);
    const trailerModal = useRef()
    let heading = []

    const setDocumentDescription = (description) => {
        const metaTag = document.querySelector('meta[name="description"]');
        if (metaTag) {
            metaTag.setAttribute('content', description);
        } else {
            const newMetaTag = document.createElement('meta');
            newMetaTag.setAttribute('name', 'description');
            newMetaTag.setAttribute('content', description);
            document.head.appendChild(newMetaTag);
        }
    };

    useEffect(() => {
        (() => {
            const HomeHeading = [`Drama Movies`, 'Movies Trending Today', 'Comedy Movies', 'Animated Movies', 'Documentary TV Shows', 'Comedy TV Shows', 'Mystery TV Shows', 'TV Shows Trending Today',]
            heading = HomeHeading
            let array = []
            //const trailers = await Trailer('97546', 'tv')
            TrailerAsync('97546', 'tv').then(resp => {
                let results = resp.data.results                
                results = results.filter((x) => { return x.type === 'Trailer' })
                setTrailerId(results[0].key)                
            })
            
            getHome().then(res => {
                //console.log(res.data)
                let array = []
                for (let [index, val] of res.data?.result?.entries()) {
                    let response = ListResultHandler(
                        val.data,
                        val?.label,
                        val?.url);
                   array.push(response)
                }
                // console.log(array)
                setHomeLists(array);
                setLoader(false);

            })
            // homeList.map(async (x, i) => {
            //     GetListAsync(x).then(resp => {
            //         let response = ListResultHandler(resp.data.results, HomeHeading[i], x)
            //         array.push(response)
            //         let known = new Set()
            //         let filtered = array.map(subarray =>
            //             subarray.filter(item => !known.has(item.poster_path) && known.add(item.poster_path))
            //         )
            //         setHomeLists(filtered)
            //         setLoader(false)
            //     })
            //     // await GetList(x, HomeHeading[i]).then((response) => {
            //     //     array.push(response)
            //     //     let known = new Set()
            //     //     let filtered = array.map(subarray =>
            //     //         subarray.filter(item => !known.has(item.poster_path) && known.add(item.poster_path))
            //     //     )
            //     //     setHomeLists(filtered)
            //     //     setLoader(false)
            //     // })
            // })
        })
            ();
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!trailerModal?.current?.contains(event.target)) {
                setTrailerActive(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [trailerModal]);

    useEffect(() => {
        document.title = "StreamDip | Search all your streaming services here";
        setDocumentDescription('StreamDip lets you search for Movies and Series across all Streaming Services in one place! Search Netflix, Apple TV+, Disney+, Prime Video, HBO Max and more on StreamDip');
    }, [])

    useEffect(() => {
        window.onload = () => {
            setIsLoaded(true);
        };
    }, []);



    return (
        <div>
            <section className="detail_section detail_section_home">
                <div className="container-fluid">
                    <div className="background_path_home" style={{ backgroundImage: 'url(https://image.tmdb.org/t/p/w200/wImNeqxKsqmJ5OBw8j3I37GNFN3.jpg)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                        <div className="row main_detail_row" style={{ backgroundImage: 'linear-gradient(to right, rgb(22, 22, 26) 150px, rgba(22, 22, 26, 0.84) 100%)' }}>
                            <div className="col-lg-12 col-md-12">
                                <div className="row">
                                    <div className="col-md-2 col-6">
                                        <div className="detail_poster">
                                            <img src="https://image.tmdb.org/t/p/w200/5fhZdwP1DVJ0FyVH6vrFdHwpXIn.jpg" width='230' height='350' style={{ maxWidth: "100%", height: "auto" }} alt="" />
                                            {/* <i class="fa fa-heart-o fav_mobile" aria-hidden="true"></i> */}
                                        </div>
                                    </div>
                                    <div className="col-md-9 col-6">
                                        <div className="movie_details">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12">
                                                    <h2 className="title mb-2 text-white">Ted Lasso</h2>
                                                    <p className="release_date mb-0">April 12th 2023</p>
                                                    <p className="genre mb-0">
                                                        <small>Comedy<span className="mx-1">.</span></small><small>Drama<span className="mx-1">.</span></small>
                                                    </p>
                                                    <p className="mt-3" >
                                                        <span className='play_trailer' data-bs-toggle="modal" data-bs-target="#trailerModal" onClick={() => setTrailerActive(true)}><BsFillPlayFill style={{ fontSize: '30px', marginLeft: '-7px', marginTop: '-3px' }} /> Play Trailer</span>
                                                    </p>
                                                    <div className='movie_details_mob'>
                                                        <div>
                                                            <h6 className="text-white watch_here_label" style={{ fontSize: '12px' }}>Stream or sign up here</h6>
                                                            <div className="watch_provider_slider">
                                                                <a href="https://tv.apple.com/show/ted-lasso/umc.cmc.vtoh0mn0xn7t3c643xqonfzy?itsct=tv_box_link&itscg=30200&at=1010l3a7G&ct=streamdip" target='_blank'><img src="/bannerwatchprovider.jpg" width="60" height='60' className="me-2 rounded watch_provider_icon" alt="" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='movie_details_desktop'>
                                                <h5 className="overview_label mt-1 text-white">Overview</h5>
                                                <p className="overview">Ted Lasso, an American football coach, moves to England when he’s hired to manage a soccer team—despite having no experience. With cynical players and a doubtful town, will he get them to see the Ted Lasso Way?</p>
                                                <div className="col-md-6">
                                                    <h6 className="text-white watch_here_label">Stream or sign up here</h6>
                                                    <div className="watch_provider_slider">
                                                        <a href="https://tv.apple.com/show/ted-lasso/umc.cmc.vtoh0mn0xn7t3c643xqonfzy?itsct=tv_box_link&itscg=30200&at=1010l3a7G&ct=streamdip" target='_blank'><img src="/bannerwatchprovider.jpg" width="60" height='60' className="me-2 rounded watch_provider_icon" alt="" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='movie_details_mob'>
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Overview <AiFillCaretDown className='fa-angle-down' /> <AiFillCaretUp className="fa-angle-up" />
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        Ted Lasso, an American football coach, moves to England when he’s hired to manage a soccer team—despite having no experience. With cynical players and a doubtful town, will he get them to see the Ted Lasso Way?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='hompage_slider'>
                <SlickSlider list={homeLists} heading={heading} seturl={seturl} setLoadMoreArray={setLoadMoreArray} setPages={setPages} setPagesNo={setPagesNo} />
                <ThreeCircles
                    height="100"
                    width="100"
                    color="#ffe01b"
                    wrapperStyle={{ margin: 'auto' }}
                    wrapperClass="loader"
                    visible={loader}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                />
            </div>

            <div className="modal fade" id="trailerModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog bg-black">
                    <div className="modal-content position-relative h-100">
                        <div className="modal-header bg-black border-0 ">
                            <h5 className="modal-title text-white" id="staticBackdropLabel">Play Trailer</h5>
                            <button type="button" className='btn' data-bs-dismiss="modal" aria-label="Close" onClick={() => setTrailerActive(false)}><i style={{ fontSize: "24px" }} className="fa fa-times text-white" aria-hidden="true"></i></button>
                        </div>
                        {trailerActive ?
                            <iframe src={`https://www.youtube.com/embed/${trailerId}?autoplay=1`}
                                frameborder='0'
                                allow='autoplay; encrypted-media'
                                allowfullscreen
                                title='video'
                                className='responsive-iframe'
                            />
                            :
                            ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
