import React, { useState, useEffect, useRef} from 'react'
import { useParams, useLocation } from 'react-router-dom';
import Slider from "react-slick";
import '../../SlickSlider/SlickSlider.css'
import { GetDetails, GetDetailsFromStreamDip } from '../../APIS/apis';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SeasonsCard from './SeasonsCard';
import EpisodesSlider from './EpisodesSlider';

function getOrderedAndFileteredSeasons(s) {
  const localSeasons = s.slice(0).filter(s => s.season_number>0)
  let last_with_poster_path = localSeasons.filter(s => s.poster_path!=null).slice(-1)
  
  localSeasons.sort(function(a,b) {
    return b.season_number - a.season_number;
  });  
  
  return localSeasons.map(s => {
    if(s['poster_path']==null) {      
      s['poster_path'] = last_with_poster_path?.[0]?.['poster_path']
      return s
    }
    return s
  }, { last_with_poster_path: last_with_poster_path } )
}

function SeasonsSlider({ list }) {
  const [seasons] = useState(
    getOrderedAndFileteredSeasons(list),
  );
  const params = useParams()
  const location = useLocation()
  const [type] = useState(location.pathname.includes('find-series/') ? 'tv' : 'movie')
  let episodeSlider = useRef(null);
  let [seasonSelected, setSeasonSelected] = useState(null)
  let [episodeOverview, setEpisodeOverview] = useState(null)
  const [episodes, setEpisodes] = useState([])
  let [showMore, setShowMore] = useState([])
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const mySetSeasonSelected = (s) => {
    if(seasonSelected===s) {
      setSeasonSelected(null)
      return
    }
    setSeasonSelected(s)
    setShowMore([])
    
    if(typeof episodeSlider.slickGoTo === 'function') {
      episodeSlider.slickGoTo(0)
    }
  }

  const addToShowMore = (episode) => {    
    setEpisodeOverview(episode.overview)
    setShow(true)
  }

  const settings = {    
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 4,
    arrow: true,
    variableWidth: true,    
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          arrow: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          arrow: false,
        }
      }
    ]
  };

  useEffect(() => {
    (async () => {
      // console.log(`${seasonSelected} was selected into season scope`)
      let detail = await GetDetailsFromStreamDip(params.id, type, params.title, seasonSelected, null)      
      setEpisodes(detail?.season?.episodes)
    })
    ();
    
}, [params.id, params.title, params.type, seasonSelected])

  return (        
    <>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Overview</Modal.Title>
        </Modal.Header>
        <Modal.Body>{episodeOverview}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>
            Close
          </Button>         
        </Modal.Footer>
      </Modal>

      <section className='seasons'>
          <div className='container-fluid'>
              <div className='row seasons_row'>
                  <div className='col-md-12'>
                      <h2 className='text-white'>{seasons.length + ' ' + (seasons?.length === 1 ? 'Season' : 'Seasons')}</h2>
                      <span className='desktop_slick_slider_parent'>
                          {seasons?.length < 7 ? (
                              <div className='row'>
                                  {seasons?.map((x, i) => <SeasonsCard key={x} season={x} setSeasonSelected={mySetSeasonSelected} seasonSelected={seasonSelected} mobile={false} numeber_of_seasons={seasons?.numeber_of_seasons}/>)}
                                  
                              </div>
                          ) : (
                              <Slider {...settings}>
                                  {seasons?.map((x, i) => <SeasonsCard key={x} season={x} setSeasonSelected={mySetSeasonSelected} seasonSelected={seasonSelected} mobile={false} />
                              )}
                              </Slider>
                          )}
                      </span>
                      <div className='mobile_slick_slider_wrapper_parent'>
                          <div className='mobile_slick_slider_wrapper mt-3'>
                              <div className='mobile_slick_slider'>
                                  {seasons?.map((x, i) =>
                                    <SeasonsCard key={x} season={x} setSeasonSelected={mySetSeasonSelected} seasonSelected={seasonSelected} mobile={true} />
                                  )}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      
      <EpisodesSlider episodeSliderRef={episodeSlider} episodes={episodes} showMore={showMore} addToShowMore={addToShowMore} />
      
    </>
  )
}

export default SeasonsSlider
