import React, { useEffect } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { ThreeCircles } from 'react-loader-spinner'


function SearchResults({setSearch, searchResult, setSearchQuery, loader, GetSearchQuery }) {

    const navigate = useNavigate();
    const location = useLocation()
    const backDropPath = 'https://image.tmdb.org/t/p/w200'
    const { id } = useParams();

    useEffect(() => {
        GetSearchQuery(location.pathname.slice(8).replace(/-/g, ' '))
        setSearchQuery(location.pathname.slice(8).replace(/-/g, ' '))
    }, [])

    useEffect(() => {
        GetSearchQuery(location.pathname.slice(8).replace(/-/g, ' '))
        setSearchQuery(location.pathname.slice(8).replace(/-/g, ' '))
    }, [id])


    return (
        <>
            {!loader &&
                <div className='row search_results mt-5'>
                    {/* <div className='col-md-12 mt-5'>
                        <button onClick={() => {navigate(-3); GetSearchQuery(location.pathname.slice(8)); window.scrollTo(0, 0); setSearch(''); setSearchQuery('');}} className='btn btn_back saerch_btn_back'><i className="fa fa-angle-left" aria-hidden="true"></i> Go Back</button>
                    </div> */}
                    {searchResult && searchResult.map((x, i) => {
                        if (x.poster_path) {
                            if(x.type === 'movie'){
                                return <div className='col-lg-2 col-md-3 col-6 mt-4' key={i}>
                                <Link to={`/find-movies/${x.id}/${x.title?.replace(/\s+/g, '-')}`} onClick={() => { setSearchQuery('') }} className="nav-link" state={{ id: `${x.id}`, type: `${x.type}`, title: `${x.title}` }}>
                                    <img src={`${backDropPath}${x.poster_path}`} className='w-100' alt="" /></Link>
                            </div>
                            }else{
                                return <div className='col-lg-2 col-md-3 col-6 mt-4' key={i}>
                                <Link to={`/find-series/${x.id}/${x.title?.replace(/\s+/g, '-')}`} onClick={() => { setSearchQuery('') }} className="nav-link" state={{ id: `${x.id}`, type: `${x.type}`, title: `${x.title}` }}>
                                    <img src={`${backDropPath}${x.poster_path}`} className='w-100' alt="" /></Link>
                            </div>
                            }
                        }
                    })}
                    {searchResult == '' && <h4 className='mt-3 text-white'>Sorry, there are no matching results to your search</h4>}
                </div>}
            <ThreeCircles
                height="100"
                width="100"
                color="#ffe01b"
                wrapperStyle={{ margin: 'auto' }}
                wrapperClass="loader"
                visible={loader}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
            />
        </>
    )
}

export default SearchResults