import React from 'react'
import { BiMovie } from 'react-icons/bi';

function Parent({children, mobile, seasonSelected, season, numeber_of_seasons}) {
  
  if(!mobile && numeber_of_seasons < 7) {
    return <div className='col-md-2 col-4'>
        <div className="season_section" style={seasonSelected === season.season_number ? {width: 190} : {width: 150}}>
          {children}
        </div>
      </div>            
  }

  if(!mobile) {
    return <div className="season_section" style={seasonSelected === season.season_number ? {width: 190} : {width: 150}}>
      <div className={seasonSelected === season.season_number ? 'season_wrapper_selected' : 'season_wrapper'}>
        <div className={`card`}>
          {children}
        </div>
      </div>
    </div>        
  }

  return children
}

function Child({season, setSeasonSelected, seasonSelected}) {
  const backDropPath = 'https://image.tmdb.org/t/p/w200'

  return <div onClick={() => setSeasonSelected(season.season_number)} className="nav-link">
      <div className={`slide_card ${seasonSelected === season.season_number ? 'season-selected' : ''}`}>
        {
        season.poster_path ?
          <img className='w-100' src={`${backDropPath}${season.poster_path}`} alt="" />
          :
          <div className='placeholder_div'>
            <img src='/placeholder.png' className="card-img-top placeholder_cast" alt="..." />
            <BiMovie className='person' />
          </div>
        }
      </div>
      <div className="card-body">
            <p className="card-title text-white h7 text-center"><strong> Season {season.season_number}</strong></p>                                             
        </div>
    </div>
}

function SeasonsCard({ season, setSeasonSelected, seasonSelected, numeber_of_seasons, mobile }) {    

    return (        
      <Parent mobile={mobile} seasonSelected={seasonSelected} season={season}>
          <Child season={season} setSeasonSelected={setSeasonSelected} seasonSelected={seasonSelected} />
      </Parent>
        
    )                                      
  

  
}

export default SeasonsCard
