import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import './SlickSlider.css'
import { ThreeCircles } from 'react-loader-spinner'
import Compressor from 'compressorjs';

// export const compressImage = async (imageUrl) => {
//   const response = await fetch(imageUrl);
//   const blob = await response.blob();

//   return new Promise((resolve, reject) => {
//     new Compressor(blob, {
//       maxWidth: 500,
//       maxHeight: 500,
//       quality: 0.4,
//       success: (compressedBlob) => {
//         const compressedUrl = URL.createObjectURL(compressedBlob);
//         const img = new Image();
//         img.src = compressedUrl;
//         img.onload = () => {
//           const canvas = document.createElement('canvas');
//           canvas.width = img.width;
//           canvas.height = img.height;
//           const ctx = canvas.getContext('2d');
//           ctx.drawImage(img, 0, 0);
//           canvas.toBlob((webpBlob) => {
//             const webpUrl = URL.createObjectURL(webpBlob);
//             resolve(webpUrl);
//           }, 'image/webp', 0.8);
//         };
//       },
//       error: (error) => {
//         reject(error);
//       },
//     });
//   });
// };

function SlickSlider({ list, heading, seturl, setLoadMoreArray, setPages, setPagesNo }) {

  const sliderRef = useRef()


  // const [imageUrl, setImageUrl] = useState([])
  const [loader, setLoader] = useState(true)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,
    arrow: true,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          arrow: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          arrow: false,
        }
      }
    ]
  };

  return (
    <>
      <div className='desktop_slick_slider_parent position-relative'>
        {list && list.length > 0 && list.map((x, i) => (          
            x.length > 1 &&
              <div className='slider_div' key={i} >
                <h2 className='text-white slider_heading mt-5'>{x[0]?.category_name && x[0]?.category_name !== undefined ? x[0]?.category_name : heading[i]}</h2>
                <div className='desktop_slick_slider'>
                  <Slider {...settings} className='abc' ref={sliderRef}>
                    {x?.map((y, j) => {
                      if (y.poster_path) {
                        if (y.type === 'movie') {
                          return <Link to={`find-movies/${y.id}/${y.title?.replace(/\s+/g, '-')}`} className="nav-link" key={j} state={{ id: `${y.id}`, type: `${y.type}`, title: `${y.title}` }} aria-label={y.title}>
                            <div className='slide_card' >
                              <img className='w-100 slider_img' src={`https://image.tmdb.org/t/p/w200${y.poster_path}`} width='100' height='100' style={{ maxWidth: "100%", height: "auto" }} alt={y.title} loading="lazy" />
                            </div>
                          </Link>
                        } else {
                          return <Link to={`find-series/${y.id}/${y.title?.replace(/\s+/g, '-')}`} className="nav-link" key={j} state={{ id: `${y.id}`, type: `${y.type}`, title: `${y.title}` }} aria-label={y.title}>
                            <div className='slide_card' >
                              <img className='w-100 slider_img' src={`https://image.tmdb.org/t/p/w200${y.poster_path}`} width='100' height='100' style={{ maxWidth: "100%", height: "auto" }} alt={y.title} loading="lazy" />
                            </div>
                          </Link>
                        }
                      }
                    })}
                    {list[0][0].poster_path !== undefined && list[i].length > 5 ?
                      <Link to={`/${x[0].category_name.trim().replace(/\s+/g, '-')}`} state={{ url: `${x[0].url}`, name: `${x[0].category_name}` }} onClick={() => {seturl(x[0].category_name.trim());setLoadMoreArray([]);setPages('');setPagesNo(1)}}><div><img src="/loadmore.png" className='rounded' width='100' height='100' style={{ maxWidth: "100%", height: "auto", width: '100%' }} alt="" /></div></Link>
                      : ''
                    }
                  </Slider>
                </div>
              </div>
        ))}
      </div>
      <div className='mobile_slick_slider_wrapper_parent'>
        {list && list.map((x, i) => (
          x.length > 1 &&
              <div className='mobile_slick_slider_wrapper mt-3' key={i}>
                <h2 className='text-white slider_heading'>{x[0]?.category_name && x[0]?.category_name !== undefined ? x[0]?.category_name : heading[i]}</h2>
                <div className='mobile_slick_slider'>
                  {x?.map((y, j) => {
                    if (y.poster_path) {
                      if (y.type === 'movie') {
                        return <Link to={`find-movies/${y.id}/${y.title?.replace(/\s+/g, '-')}`} className="nav-link" key={j} state={{ id: `${y.id}`, type: `${y.type}`, title: `${y.title}` }} aria-label={y.title}><div className='slide_card' ><img className='slider_img' width='101px' height='151px' src={`https://image.tmdb.org/t/p/w200${y.poster_path}`} alt={y.title} loading="lazy" /></div></Link>
                      } else {
                        return <Link to={`find-series/${y.id}/${y.title?.replace(/\s+/g, '-')}`} className="nav-link" key={j} state={{ id: `${y.id}`, type: `${y.type}`, title: `${y.title}` }} aria-label={y.title}><div className='slide_card' ><img className='slider_img' width='101px' height='151px' src={`https://image.tmdb.org/t/p/w200${y.poster_path}`} alt={y.title} loading="lazy" /></div></Link>
                      }
                    }
                  })}
                  {list[0][0].poster_path !== undefined && list[i].length > 5 ?
                    <Link className="nav-link" to={`/${x[0].category_name.trim().replace(/\s+/g, '-')}`} state={{ url: `${x[0].url}`, name: `${x[0].category_name}` }} onClick={() => {seturl(x[0].category_name.trim());setLoadMoreArray([]);setPages('');setPagesNo(1)}}><div><img src="/loadmore.png" className='rounded' width='101px' height='151px' alt="" /></div></Link>
                    : ''}
                </div>
              </div>
        ))}
      </div>
    </>
  )
}

export default SlickSlider
