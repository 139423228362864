import React from 'react'
const SD_API = process.env.REACT_APP_API_URL || '';

function WatchProviderLink({ providerName, title, logoPath, type, detail }) {
        const backDropPath = 'https://image.tmdb.org/t/p/w200'

        const providers = {
                'Netflix': { 'url': `https://www.netflix.com/search?q=%s`, streamdipProvider: 'netflix' },
                'Netflix basic with Ads': { 'url': `https://www.netflix.com/search?q=%s`, streamdipProvider: 'netflix' },
                'Netflix Kids': { 'url': `https://www.netflix.com/search?q=%s`, streamdipProvider: 'netflix'},
                'Hulu': { 'url': `https://www.hulu.com/`, streamdipProvider: 'hulu'},
                'Amazon Prime Video': { 'url': `https://www.primevideo.com/search/ref=atv_nb_sug?ie=UTF8&phrase=${title.toLowerCase()}`, streamdipProvider: 'primevideo' },
                'Amazon Video': { 'url': `https://www.primevideo.com/search/ref=atv_nb_sug?ie=UTF8&phrase=${title.toLowerCase()}`, streamdipProvider: 'primevideo' },
                'HBO': { 'url': `https://hbomax.prf.hn/click/camref:1101lvYV4/pubref:streamdip/ar:web` },
                'Max': { 'url': `https://hbomax.prf.hn/click/camref:1101lvYV4/pubref:streamdip/ar:web` },
                'HBO Go': { 'url': `https://hbomax.prf.hn/click/camref:1101lvYV4/pubref:streamdip/ar:web` },
                'HBO Max Free': { 'url': `https://hbomax.prf.hn/click/camref:1101lvYV4/pubref:streamdip/ar:web` },
                'Apple TV+': { 'url': `https://tv.apple.com/search?term=${title.toLowerCase()}&at=1010l3a7G` , streamdipProvider: 'apple'},
                'Apple TV': { 'url': `https://tv.apple.com/search?term=${title.toLowerCase()}&at=1010l3a7G` , streamdipProvider: 'apple'},
                'Apple TV Plus': { 'url': `https://tv.apple.com/search?term=${title.toLowerCase()}&at=1010l3a7G` , streamdipProvider: 'apple'},
                'Google Play Movies': { 'url': `https://play.google.com/store/search?q=${title.toLowerCase()}&c=movies`, streamdipProvider: 'googleplay' },
                'Vudu': { 'url': `https://www.vudu.com/content/movies/search?minVisible=0&returnUrl=%252Fcontent%252Fbrowse%252Fmarketing%252Fwatch-movies-tv&searchString=${title.toLowerCase()}` },
                'Stan': { 'url': `https://www.stan.com.au/watch/${title.toLowerCase()}`, streamdipProvider: 'stan' },
                'Sky Go': { 'url': `https://www.sky.com/new-search?q=${title.toLowerCase()}`, streamdipProvider: 'sky' },
                'BBC iPlayer': { 'url': `https://www.bbc.co.uk/iplayer/search?q=${title.toLowerCase()}` },
                'Sky Store': { 'url': `https://www.skystore.com/search?q=${title.toLowerCase()}`, streamdipProvider: 'skystore' },
                'Sky': { 'url': `https://www.skystore.com/search?q=${title.toLowerCase()}`, streamdipProvider: 'skystore' },
                'BINGE': { 'url': `https://binge.com.au/search?q=${title.toLowerCase()}`, streamdipProvider: 'binge'},
                'Foxtel Now': { 'url': `https://www.foxtel.com.au/watch/${type === 'movie' ? 'movies' : 'tv-shows'}/${title.toLowerCase()}.html`, streamdipProvider: 'foxtel' },
                'Disney Plus': { 'url': `https://www.disneyplus.com/${type === 'movie' ? 'movies' : 'series'}/${title.toLowerCase()}`, streamdipProvider: 'disneyplus' },
                'Disney+': { 'url': `https://www.disneyplus.com/${type === 'movie' ? 'movies' : 'series'}/${title.toLowerCase()}`, streamdipProvider: 'disneyplus' },
                'Peacock': { 'url': `https://www.peacocktv.com/${type === 'movie' ? 'stream-movies/' : 'stream-tv/'}${title.toLowerCase()}`, streamdipProvider: 'peacock' },
                'Peacock Premium': { 'url': `https://www.peacocktv.com/${type === 'movie' ? 'stream-movies/' : 'stream-tv/'}${title.toLowerCase()}`, streamdipProvider: 'peacock' },
                'SkyShowtime': { 'url': `https://www.skystore.com/search?q=${title.toLowerCase()}` },
                'Paramount Plus': { 'url': `https://www.paramountplus.com/search/${title.toLowerCase()}`, streamdipProvider: 'paramountplus' },
                'Paramount Plus Apple TV Channel': { 'url': `https://www.paramountplus.com/search/${title.toLowerCase()}`, streamdipProvider: 'paramountplus' },
                'Paramount+ Apple TV Channel': { 'url': `https://www.paramountplus.com/search/${title.toLowerCase()}`, streamdipProvider: 'paramountplus' },
                'Paramount+': { 'url': `https://www.paramountplus.com/search/${title.toLowerCase()}`, streamdipProvider: 'paramountplus' },
                'Paramount+ Amazon Channel': { 'url': `https://www.paramountplus.com/search/${title.toLowerCase()}`, streamdipProvider: 'paramountplus' },
                'Paramount+ Roku Premium Channel': { 'url': `https://www.paramountplus.com/search/${title.toLowerCase()}`, streamdipProvider: 'paramountplus' },
                'Paramount+ Showtime': { 'url': `https://www.paramountplus.com/search/${title.toLowerCase()}`, streamdipProvider: 'paramountplus' },
                'YouTube': { 'url': `https://www.youtube.com/results?search_query=${title.toLowerCase()}` },
                'Microsoft Store': { 'url': `https://www.youtube.com/results?search_query=${title.toLowerCase()}`, streamdipProvider: 'microsoftstore'},
                'Crave' : { url: `https://www.crave.ca/en/search/${title.toLowerCase()}`, streamdipProvider: 'crave'},
                '7plus' : { url: `https://7plus.com.au/search`, streamdipProvider: 'sevenplus'}


        }

        const generateLink = function (providerName, title, logoPath) {
                
                if (providers[providerName] != null) {
                        // console.log(providers?.providerName?.['streamdipProvider'])
                        let isStreamdipRedirect = providers[providerName]!==undefined && providers[providerName]['streamdipProvider'] !== undefined;
                        let url = isStreamdipRedirect ?
                                `${SD_API}/items/${detail?.type}/${detail?.id}/${detail?.title}/${providers[providerName]['streamdipProvider']}/redirect` :
                                providers[providerName].url?.replace('%s', title.toLowerCase());
                        
                        return <a href={url} target="_blank" rel="noreferrer">
                                <img src={`${backDropPath}${logoPath}`} width='60px' className='me-2 rounded watch_provider_icon' alt={providerName} /></a>
                }
                return <img src={`${backDropPath}${logoPath}`} width='60px' className='me-2 rounded watch_provider_icon' alt={providerName} />
        }

        return generateLink(providerName, title, logoPath)

}

export default WatchProviderLink
