import React from 'react'
import WatchProviderLink from './WatchProviderLink';

function WatchProviders({ details }) {

    return (
        <div className='movie_details_mob'>
            {details?.watch?.length !== 0 && <h6 className='text-white watch_here_label'>Stream or sign up here</h6>}
            <div className='watch_provider_slider_mob'>
                {details?.watch?.length !== 0 && details?.watch?.map((x, i) => (
                    x?.provider_name !== 'HBO Max' &&
                    <WatchProviderLink key={i} providerName={x?.provider_name} title={details.original_title} logoPath={x?.logo_path} detail={details} />
                ))}
            </div>
            {details?.buy_rent && <h6 className='text-white watch_here_label mt-3'>Buy or Rent here</h6>}
            <div className='buy_rent_slider_mob'>
                {details?.buy_rent?.map((x, i) => (
                    x?.provider_name !== 'HBO Max' &&
                    <WatchProviderLink key={i} providerName={x?.provider_name} title={details.original_title} logoPath={x?.logo_path} detail={details} />
                ))}
            </div>
            {details?.watch?.length > 0 || details?.buy_rent_provider !== undefined ? '' :
                <div className='col-md-6'>
                    <h6 className='text-white watch_here_label py-3'>Not available to stream in your location</h6>
                </div>
            }
        </div>
    )
}

export default WatchProviders
