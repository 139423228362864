import React, { useState, useRef } from 'react'
import './SignUp.css'
import { AiOutlineClose } from 'react-icons/ai';
import { signUp, emailOtp } from '../APIS/apis'
import { ThreeCircles } from 'react-loader-spinner'


function SignUP() {

    const [signUpCredentials, setSignUpCredentials] = useState({
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        confirm_password: ''
    })
    const [loader, setLoader] = useState(false)
    const closeRef = useRef();
    const [message, setMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [otp, setOtp] = useState('')
    const [enableVerificationModal, setEnableVerificationModal] = useState(false)

    const signUpHandler = (e) => {
        setMessage('')
        setSignUpCredentials({ ...signUpCredentials, [e.target.name]: e.target.value })
    }

    const userSignUp = async (event) => {
        event.preventDefault()
        setMessage('')
        setLoader(true)

        if (signUpCredentials.email == '' || signUpCredentials.password == '' || signUpCredentials.first_name == '' || signUpCredentials.last_name == '' || signUpCredentials.confirm_password == '') {
            setMessage('All Fields Are Required')
            setLoader(false)
        } else if (!signUpCredentials.first_name.match(/^[a-zA-Z ]+$/) || !signUpCredentials.last_name.match(/^[a-zA-Z ]+$/)) {
            setLoader(false)
            setMessage('First name and last name should only contain letters')
        } else if (!signUpCredentials.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            || !signUpCredentials.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setLoader(false)
            setMessage('Please enter a valid email address')
        } else if (!signUpCredentials.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/)) {
            setLoader(false)
            setMessage('Password must have at least 8 characters with at least 1 uppercase letter, 1 lowercase letter and 1 number')
        } else if (signUpCredentials.confirm_password !== signUpCredentials.password) {
            setMessage('Password and confirm password must be same')
            setLoader(false)
        } else {
            await signUp(signUpCredentials).then((data) => {
                if(data.status === false){
                    setMessage(data.message)
                    setLoader(false)
                }else{
                    setEnableVerificationModal(true)
                    setLoader(false)
                    setSuccessMessage('Check your inbox for your OTP code.')
                }
            }).catch((error) => {
                setLoader(false)
                setMessage('')
            })
        }
    }

    const emailVerification = async (event) => {
        event.preventDefault()
        setLoader(true)
        await emailOtp(signUpCredentials.email, otp).then((data) => {
            if(data.status === false){
                setMessage(data.message)
                setLoader(false)
            }else{
                setSuccessMessage('You have signed up successfully')
                setTimeout(() => {
                    setLoader(false)
                    closeRef.current.click()
                    setEnableVerificationModal(false)
                    setOtp('')
                    setSignUpCredentials('')
                }, 1000);
            }
        }).catch((error) => {

            console.log(error)

        })
    }

    return (
        <div>
            <div className="modal fade" id="signup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content signup__process">
                        <div className="modal-header">
                            <h5 className="modal-title text-white" id="exampleModalLabel">{!enableVerificationModal ? <span>Sign Up</span> : <span>Email Verification</span>}</h5>
                            <button className='btn' disabled={loader} data-bs-dismiss="modal" ref={closeRef}><AiOutlineClose className='text-white' /></button>
                        </div>
                        <div className="modal-body  text-end">
                            {!enableVerificationModal ?
                                <form onSubmit={userSignUp}>
                                    <div className="mb-3 text-start">
                                        <label htmlFor="exampleInputEmail1" className="form-label text-white">First Name</label>
                                        <input type="text" className="form-control" id="f_name" value={signUpCredentials.first_name} name="first_name" placeholder="First Name" onChange={(e) => signUpHandler(e)} />
                                    </div>
                                    <div className="mb-3 text-start">
                                        <label htmlFor="exampleInputEmail1" className="form-label text-white">Last Name</label>
                                        <input type="text" className="form-control" id="l_name" value={signUpCredentials.last_name} name="last_name" placeholder="Last Name" onChange={(e) => signUpHandler(e)} />
                                    </div>
                                    <div className="mb-3 text-start">
                                        <label htmlFor="exampleInputEmail1" className="form-label text-white">Email</label>
                                        <input type="email" autoComplete="email" className="form-control" id="email" value={signUpCredentials.email} name="email" placeholder="Email" onChange={(e) => signUpHandler(e)} />
                                    </div>
                                    <div className="mb-3 text-start">
                                        <label htmlFor="exampleInputPassword1" className="form-label text-white">Password</label>
                                        <input type="password" autoComplete="new-password" className="form-control" id="exampleInputPassword1" value={signUpCredentials.password} name="password" placeholder="Password" onChange={(e) => signUpHandler(e)} />
                                    </div>
                                    <div className="text-start">
                                        <label htmlFor="exampleInputPassword1" className="form-label text-white">Confirm Password</label>
                                        <input type="password" autoComplete="new-password" className="form-control mb-0" id="c_password" name="confirm_password" value={signUpCredentials.confirm_password} placeholder="Confirm Password" onChange={(e) => signUpHandler(e)} />
                                    </div>
                                    <p className='text-danger text-start'><small>{message}</small></p>
                                    <button disabled={loader} className="btn btn__signup position-relative">
                                        {!loader &&
                                            <>
                                                Sign Up
                                            </>
                                        }
                                        <ThreeCircles
                                            height="20"
                                            width="20"
                                            color="#000"
                                            wrapperStyle={{ margin: 'auto' }}
                                            wrapperClass="loader"
                                            visible={loader}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor=""
                                            innerCircleColor=""
                                            middleCircleColor=""
                                        />
                                    </button>
                                </form>
                                :
                                <form onSubmit={emailVerification}>
                                    <div className="mb-3 text-start">
                                        <label htmlFor="exampleInputEmail1" className="form-label text-white">OTP</label>
                                        <input type="text" value={otp} className="form-control mb-0" id="otp" name="otp" placeholder="OTP" onChange={(e) => {setOtp(e.target.value); setMessage(''); setSuccessMessage('')}} />
                                    </div>
                                    <p className='text-danger text-start'><small>{message}</small></p>
                                    <p className='text-success text-start'><small>{successMessage}</small></p>
                                    <button type='submit' disabled={loader} className="btn btn__signup position-relative">
                                        {!loader &&
                                            'Verify'
                                        }
                                        <ThreeCircles
                                            height="20"
                                            width="20"
                                            color="#000"
                                            wrapperStyle={{ margin: 'auto' }}
                                            wrapperClass="loader"
                                            visible={loader}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor=""
                                            innerCircleColor=""
                                            middleCircleColor=""
                                        />
                                    </button>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUP