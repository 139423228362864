import React, { useState } from 'react'
import style from './AdminLogin.module.css'
import { adminSignIn } from '../../APIS/apis'
import { setCookie } from '../../Services/TokenFunction'
import { useNavigate } from "react-router-dom";

function AdminLogin({ setIsAdminAuthenticated }) {

    const [adminLogin, setAdminLogin] = useState({
        email: '',
        password: ''
    })
    const navigate = useNavigate();

    const loginHandler = (e) => {
        setAdminLogin({ ...adminLogin, [e.target.name]: e.target.value })
    }

    

    const login = async (event) => {
        event.preventDefault()
        const loginDetails = await adminSignIn(adminLogin)
        if (loginDetails.token) {
            setIsAdminAuthenticated(true)
            setCookie('token', loginDetails.token, 30)
            navigate("/admin-dashboard");
        }
    }

    return (
        <div className={`${style.admin_login}`}>
            <form onSubmit={login}>
                <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">Email address</label>
                    <input type="email" name="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => loginHandler(e)} />
                </div>
                <div className="mb-3">
                    <label for="exampleInputPassword1" className="form-label">Password</label>
                    <input type="password" name="password" className="form-control" id="exampleInputPassword1" onChange={(e) => loginHandler(e)} />
                </div>
                <button type="submit" className="btn btn-primary">Login</button>
            </form>
        </div>
    )
}

export default AdminLogin