import './App.css';
import Navbar from './NavBar/navbar';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Details from './Detail/Details';
import SeasonDetails from './Detail/SeasonDetails';
import SearchResults from './SearchResults/SearchResults';
import { useState, useEffect, useRef } from 'react';
import { Search } from "./APIS/apis"
import Categories from './Categories/Categories';
import Home from './Home/Home';
import {  } from 'react-router-dom'
import LoadMore from './LoadMore/LoadMore';
import Signin from './SignIn/Signin';
import SignUP from './SignUp/SignUP';
import { getCookie } from './Services/TokenFunction';
import jwt_decode from "jwt-decode";
import WatchList from './WatchList/WatchList';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import PasswdReset from './PasswdReset/PasswdReset'
import Profile from './Profile/Profile';
import Footer from './Footer/Footer';
import TermsCondition from './TermsConditions/TermsCondition';
import About from './About/About';
import Developer from './Developer/Developer';
import Cookies from './Cookies/Cookies';
import AdminLogin from './AdminPanel/AdminLogin/AdminLogin';
import AdminPanel from './AdminPanel/AdminPanel';
import { UserProvider } from './contexts/UserContext';
// const SeasonDetails = lazy(()=> import('./Detail/SeasonDetails.js'));

function App() {
  const [searchResult, setSearchResult] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [loader, setLoader] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectedWatchProvider, setSelectedWatchProvider] = useState([])
  const [url, seturl] = useState(null)
  const location = useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false)
  const [userDetails, setUserDetails] = useState('')
  const [search, setSearch] = useState('')
  const [userCountry, setUserCountry] = useState('')
  const inputRef = useRef(null);
  const categoryResults = useRef([])
  let [loadMoreArray, setLoadMoreArray] = useState([])
  const [pages, setPages] = useState('')
  const listOfCategories = useRef(1)
  const [pagesNo, setPagesNo] = useState(1)
 

  const navigate = useNavigate();

  const GetSearchQuery = async (query) => {
    setSearchQuery(query)
    seturl(query)
    if (!query) {
      navigate(`/`);
    } else {
      navigate(`/search/${query.replace(/\s+/g, '-')}`);
    }
    window.scrollTo(0, 0)
    inputRef.current.blur()
  }

  const categorySelected = (movie, tv) => {
    if (movie.length > 0 || tv.length > 0) {
      navigate('/')
    }
    let genreArray = []
    genreArray.push(movie, tv)
    genreArray = genreArray.flat()
    setSelectedCategory(genreArray)
  }

  const watchProviderSelected = (query) => {
    if (query.length > 0) {
      navigate('/')
    }
    setSelectedWatchProvider(query)
  }

  const implementFilter = () => {
    if ((selectedCategory.length > 0 || selectedWatchProvider.length > 0) && location.pathname == '/') {
      return <Categories categoryResults={categoryResults} userCountry={userCountry} selectedCategory={selectedCategory} selectedWatchProvider={selectedWatchProvider} seturl={seturl} setLoadMoreArray={setLoadMoreArray} setPages={setPages} setPagesNo={setPagesNo} />
    } else if (selectedCategory.length == [''] && location.pathname == '/') {
      return <Home userCountry={userCountry} seturl={seturl} setLoadMoreArray={setLoadMoreArray} setPages={setPages} setPagesNo={setPagesNo} />
    }
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }



  useEffect(() => {
    if (isAuthenticated) {
      const token = getCookie('token')
      if (token) {
        var decoded = jwt_decode(token);
        setUserDetails(decoded)
      }
    }
  }, [isAuthenticated, isAdminAuthenticated])

  useEffect(() => {
    const token = getCookie('token')
    if (token) {
      var decoded = jwt_decode(token);
      setUserDetails(decoded)
      setIsAuthenticated(true)
    }
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setLoader(true)
    setSearchResult('')
    //console.log('searchQuery useEffect')
    const getData = setTimeout(async () => {
      if (searchQuery) {
        const searchedResults = await Search(searchQuery)
        setSearchResult(searchedResults.sort(dynamicSort("popularity")))
      }
      setLoader(false)
    }, 2000)

    return () => clearTimeout(getData)
  }, [searchQuery])



  return (
    <UserProvider>
      {!location.pathname.includes("admin") ?
        <Navbar categoryResults={categoryResults} inputRef={inputRef} setUserCountry={setUserCountry} search={search} setSearch={setSearch} setSelectedCategory={setSelectedCategory} userDetails={userDetails} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} GetSearchQuery={GetSearchQuery} searchQuery={searchQuery} categorySelected={categorySelected} watchProviderSelected={watchProviderSelected} setSelectedWatchProvider={setSelectedWatchProvider} />
        : ""}
      <div className='page_body pt-1'>
        <div className='min-vh-100'>
          {
            implementFilter()
          }
          <Routes>
            <Route path={`/search/:id`} element={<SearchResults userDetails setSearch={setSearch} GetSearchQuery={GetSearchQuery} searchResult={searchResult} setSearchQuery={setSearchQuery} loader={loader} />} />
            <Route path={`/find-movies/:id/:title`} element={<Details userCountry={userCountry} userDetails={userDetails} />} />
            <Route path={`/find-series/:id/:title`} element={<Details userCountry={userCountry} userDetails={userDetails} />} />
            <Route path={`/find-series/:id/:title/Season/:seasonNumber`} element={<SeasonDetails userCountry={userCountry} userDetails={userDetails} />} />
            <Route path='/watchlist' element={<WatchList userDetails={userDetails} />} />
            <Route path={`/terms-and-conditions`} element={<TermsCondition />} />
            <Route path={`/about-us`} element={<About />} />
            <Route path={`/developer`} element={<Developer />} />
            <Route path={`/cookies`} element={<Cookies />} />
            <Route path={`/signup`} element={<SignUP />} />
            <Route path={`/forgot-password`} element={<PasswdReset />} />
            <Route path={`/:id`} element={<LoadMore loadMoreArray={loadMoreArray} setLoadMoreArray={setLoadMoreArray} pages={pages} setPages={setPages} listOfCategories={listOfCategories} setPagesNo={setPagesNo} pagesNo={pagesNo} />} />
            <Route path={`/admin-login`} element={<AdminLogin setIsAdminAuthenticated={setIsAdminAuthenticated} />}></Route>
            <Route path={`/admin-dashboard`} element={<AdminPanel setIsAdminAuthenticated={setIsAdminAuthenticated} userDetails={userDetails} />}></Route>
          </Routes>
        </div>
        {!location.pathname.includes("admin") ?
          <Footer /> :
          ''}
      </div>

      <Signin setIsAuthenticated={setIsAuthenticated} />
      <SignUP />
      <ForgotPassword />
      <Profile userDetails={userDetails} setUserDetails={setUserDetails} />
    </UserProvider>
  );
}

export default App;
