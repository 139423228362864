import React, { useEffect } from 'react'
import './TermsConditions.css'

function TermsCondition() {

  const setDocumentDescription = (description) => {
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute('content', description);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', 'description');
      newMetaTag.setAttribute('content', description);
      document.head.appendChild(newMetaTag);
    }
  };

  useEffect(() => {
    document.title = "StreamDip | Terms & Conditions";
    setDocumentDescription('StreamDip lets you search for Movies and Series across all Streaming Services in one place! Search Netflix, Apple TV+, Disney+, Prime Video, HBO Max and more on StreamDip');
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='container pt-5'>
      <h1 className='text-white pt-4 terms_header'>Terms &amp; Conditions</h1>
      <p className='text-white mt-5'>Welcome StreamDip. If you continue to browse and use this website, you are agreeing to comply
        with and be bound by the following terms and conditions of use, which together with our privacy
        policy govern the relationship with you in relation to this website. If you disagree with
        any part of these terms and conditions, please do not use our website.</p>
      <p className='text-white'>
        The use of this website is subject to the following terms of use:
      </p>
      <p className='text-white'>
        The content of the pages of this website is for your general information and use only. It is subject to
        change without notice.
      </p>
      <p className='text-white'>
        This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, the
        following personal information may be stored by us for use by third parties
      </p>
      <p className='text-white'>
        Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness,
        performance, completeness or suitability of the information and materials found or offered on this
        website for any particular purpose. You acknowledge that such information and materials may
        contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to
        the fullest extent permitted by law.
      </p>
      <p className='text-white'>
        Your use of any information or materials on this website is entirely at your own risk, for which we
        shall not be liable. It shall be your own responsibility to ensure that any products, services or
        information available through this website meet your specific requirements.
      </p>
      <p className='text-white'>
        This website contains material which is owned by or licensed to us. This material includes, but is not
        limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than
        in accordance with the copyright notice, which forms part of these terms and conditions.
      </p>
      <p className='text-white'>
        All trademarks reproduced in this website, which are not the property of, or licensed to the
        operator, are acknowledged on the website.
      </p>
      <p className='text-white'>
        Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.
      </p>
      <p className='text-white'>
        From time to time, this website may also include links to other websites. These links are provided for
        your convenience to provide further information. They do not signify that we endorse the
        website(s). We have no responsibility for the content of the linked website(s).
      </p>
    </div>
  )
}

export default TermsCondition