import React, { useState, useRef } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { ThreeCircles } from 'react-loader-spinner'
import { emailVerify, resetPasswordFunction, emailOtp, resetPasswordWithEmail } from '../APIS/apis'

function ForgotPassword() {

    const [email, setEmail] = useState('')
    const [loader, setLoader] = useState(false)
    const [otp, setOtp] = useState('')
    const [message, setMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [verifyEmailModal, setVerifyEmailModal] = useState(true)
    const [verifyOTPModal, setVerifyOTPModal] = useState(false)
    const [resetPasswordModal, setResetPasswordModal] = useState(false)
    const [resetpassword, setResetPassword] = useState({
        password: '',
        confirm_password: ''
    })
    const closeRef = useRef();

    const handlePassword = (e) => {
        setResetPassword({ ...resetpassword, [e.target.name]: e.target.value })
    }

    const emailVerification = async (event) => {
        event.preventDefault()
        setLoader(true)
        if (!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setMessage('Please enter valid email')
            setLoader(false)
        } else {
            try {
                const result = await emailVerify(email)
                if (result.status === true) {
                    setSuccessMessage('Check your inbox for your OTP code')
                    setLoader(false)
                    setTimeout(() => {
                        setVerifyEmailModal(false)
                        setVerifyOTPModal(true)
                        setMessage('')
                    }, 1000);
                }else{
                    setLoader(false)
                    setMessage(result.message)
                }
            } catch (error) {
                setMessage(`${error.response.data.message}`)
                setLoader(false)
            }
        }
    }

    const otpVerification = async (event) => {
        event.preventDefault()
        setLoader(true)
        const result = await emailOtp(email, otp)
        if (result.status === true) {
            setLoader(false)
            setVerifyOTPModal(false)
            setResetPasswordModal(true)
            setSuccessMessage(result.message)
            setMessage('')
        } else {
            setLoader(false)
            setSuccessMessage('')
            setMessage(result.message)
        }
    }

    const resetPasswordOld = async (event) => {
        event.preventDefault()
        setLoader(true)
        if (!resetpassword.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)) {
            setLoader(false)
            setMessage('Password must have at least 8 characters with at least 1 uppercase letter, 1 lowercase letter and 1 number')
        }
        else if (resetpassword.password === resetpassword.confirm_password) {
            const result = await resetPasswordFunction(email, resetpassword.password)
            if (result.status === true) {
                setLoader(false)
                setMessage('')
                setSuccessMessage(result.message)
                setTimeout(() => {
                    setMessage('')
                    setVerifyEmailModal(true)
                    setVerifyOTPModal(false)
                    setResetPasswordModal(false)
                    closeRef.current.click()
                }, 2000);
            } else {
                setLoader(false)
                setSuccessMessage('')
                setMessage(result.message)
            }
        } else {
            setMessage('Password and confirm password should be same.')
        }
    }

    const resetPassword = async (event) => {
        event.preventDefault()
        setLoader(true)
        const resp = await resetPasswordWithEmail(email)
        console.log(resp)
        if(resp.status) {
            let message = 'Follow e-mail instructions to reset yor password'
            setSuccessMessage(message)
            setMessage(message)
        }
        setLoader(false)             
    }

    return (
        <div className="modal fade" id="forgotPassword" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content email__password__signin">
                    <div className="modal-header">
                        <h5 className="modal-title text-white" id="exampleModalLabel">Reset Password</h5>
                        <button className='btn' data-bs-dismiss="modal" ref={closeRef}><AiOutlineClose className='text-white' /></button>
                    </div>
                    <div className="modal-body">
                        {resetPasswordModal &&
                            <form onSubmit={(e) => resetPassword(e)} noValidate>
                                <div className="mb-3">
                                    <input type="password" className="form-control" name="password" id="passwordField" value={resetpassword.password} placeholder="Password" aria-describedby="emailHelp" onChange={(e) => handlePassword(e)} required />
                                </div>
                                <div className="mb-0">
                                    <input type="password" className="form-control mb-0" name="confirm_password" id="confirmPasswordField" value={resetpassword.confirm_password} placeholder="Confirm Password" aria-describedby="emailHelp" onChange={(e) => handlePassword(e)} required />
                                </div>
                                <div className='login_message'>
                                    <small className="mb-3 text-success">
                                        {successMessage}
                                    </small>
                                    <small className="mb-3 text-danger">
                                        {message}
                                    </small>
                                </div>
                                <div className='text-end mt-4'>
                                    <button disabled={loader} type="submit" className="btn btn-next position-relative">
                                        {!loader &&
                                            'Reset'
                                        }
                                        <ThreeCircles
                                            height="20"
                                            width="20"
                                            color="#000"
                                            wrapperStyle={{ margin: 'auto' }}
                                            wrapperClass="loader"
                                            visible={loader}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor=""
                                            innerCircleColor=""
                                            middleCircleColor=""
                                        />
                                    </button>
                                </div>
                            </form>
                        }
                        {verifyEmailModal &&
                            <form onSubmit={(e) => resetPassword(e)} noValidate>
                                <div className="">
                                    <input type="email" autoComplete="email" className="form-control" name="email" placeholder="Email" aria-describedby="emailHelp" onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                                {message &&
                                    <small className='text-danger   '>
                                        {message}
                                    </small>
                                }
                                <div className='text-end mt-4'>
                                    <button type="submit" disabled={loader} className="btn btn-next position-relative">
                                        {!loader &&
                                            'Reset Password'
                                        }
                                        <ThreeCircles
                                            height="20"
                                            width="20"
                                            color="#000"
                                            wrapperStyle={{ margin: 'auto' }}
                                            wrapperClass="loader"
                                            visible={loader}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor=""
                                            innerCircleColor=""
                                            middleCircleColor=""
                                        />
                                    </button>
                                </div>
                            </form>
                        }
                        {verifyOTPModal &&
                            <form onSubmit={(e) => otpVerification(e)}>
                                <div>
                                    <input type="text" className="form-control mb-0" name="otp" placeholder="Enter OTP" aria-describedby="emailHelp" onChange={(e) => setOtp(e.target.value)} required />
                                    <small className="mb-3 text-success">
                                        {successMessage}
                                    </small>
                                    <small className="mb-3 text-danger">
                                        {message}
                                    </small>
                                </div>

                                <div className='text-end mt-4'>
                                    <button disabled={loader} type="submit" className="btn btn-next position-relative">
                                        {!loader &&
                                            'Next'
                                        }
                                        <ThreeCircles
                                            height="20"
                                            width="20"
                                            color="#000"
                                            wrapperStyle={{ margin: 'auto' }}
                                            wrapperClass="loader"
                                            visible={loader}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor=""
                                            innerCircleColor=""
                                            middleCircleColor=""
                                        />
                                    </button>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword