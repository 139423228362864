import React, { useState, useEffect, useRef } from 'react'
import './navbar.css';
import { GetCategoriesMovieAsync, GetWatchProvidersAsync, GetCategoriesTvAsync } from '../APIS/apis'

import axios from 'axios';

import NavBar from './components/navbar';
import Filters from './components/filters';
import SideNav from './components/sidenav';

export let countryForApi = ''

function Navbar({ categoryResults, inputRef, setUserCountry, setSearchQuery, setSearch, search, setIsAuthenticated, userDetails, GetSearchQuery, searchQuery, categorySelected, watchProviderSelected, isAuthenticated, setSelectedCategory, setSelectedWatchProvider }) {
    const [movieCategories, setMovieCategories] = useState([]);
    const [seriesCategories, setSeriesCategories] = useState([]);
    const [watchProviders, setWatchProviders] = useState([]);
    const [country, setCountry] = useState('')
    const [movieGenreFilter, setMovieGenreFilter] = useState([])
    const [seriesGenreFilter, setSeriesGenreFilter] = useState([])
    const [streamingFilter, setStreamingFilter] = useState([])
    
    const [sideBarActive, setSideBarActive] = useState(false)
    const [watchProviderSearchQuery, setWatchProviderSearchQuery] = useState('')
    
    const closeSideBar = useRef();

    let BR = ['Netflix', 'Disney+', 'Amazon Prime', 'Apple TV+', 'Star+', 'HBO Max', 'Paramount+', 'Google Play']
    let AU = ['Netflix', 'Disney+', 'Stan', 'Amazon Prime', 'Apple TV+', 'Google Play', 'Binge', 'Foxtel Now']
    let GB = ['Netflix', 'Disney+', 'Amazon Prime', 'Apple TV+', 'Google Play', 'Paramount+', 'Sky Go', 'Sky Store', 'BBC iPlayer']
    let US = ['Netflix', 'Disney+', 'Amazon Prime', 'Apple TV+', 'Hulu', 'Peacock', 'Google Play', 'Paramount+']

    const clearFiltersAndSearch = () => {
        setSelectedCategory([]);
        setSelectedWatchProvider([]);
        setMovieGenreFilter([]);
        setSeriesGenreFilter([]);
        setStreamingFilter([]);
        setSearchQuery('');
        setSearch('')
    }

    const selectCountry = (country) => {
        setCountry(country)
        localStorage.setItem('country', country)
    }

    const genreFilterSelected = (query, queryType) => {
        if (queryType === 'movie') {
            if (!movieGenreFilter.some(item => item.genre === query)) {
                setMovieGenreFilter([...movieGenreFilter, { genre: query, type: queryType }])
            } else {
                setMovieGenreFilter(movieGenreFilter.filter((x) => x.genre !== query))
            }
        } else {
            if (!seriesGenreFilter.some(item => item.genre === query)) {
                setSeriesGenreFilter([...seriesGenreFilter, { genre: query, type: queryType }])
            } else {
                setSeriesGenreFilter(seriesGenreFilter.filter((x) => x.genre !== query))
            }
        }
        categoryResults.current = []
    }

    const streamingFilterSelected = (name, id) => {
        const checkUsername = obj => obj.name === name
        if (!streamingFilter.some(checkUsername)) {
            setStreamingFilter([...streamingFilter, { name: name, id: id }])
        } else {
            setStreamingFilter(streamingFilter.filter((x) => x.name !== name))
        }
        categoryResults.current = []
    }

    const selectAllStreaming = () => {
        let watchProvider = []
        if (streamingFilter.length === watchProviders.length) {
            setStreamingFilter([])
        } else {
            watchProviders.map((x) => (
                watchProvider.push({ name: x.provider_name, id: x.provider_id })
            ))
            setStreamingFilter(watchProvider)
        }
        categoryResults.current = []
    }

    const watchProviderSearch = (e) => {
        document.getElementById('watchProviderSearchField').style.display = 'flex';
        document.getElementById('watchProviderSearch').style.display = 'none';
        document.getElementById('watchProviderSearchClose').style.display = 'block';
        document.getElementById("watchProviderSearchInput").focus();
        document.getElementById('watchProviderSearchFieldOne').style.display = 'flex';
        document.getElementById('watchProviderSearchOne').style.display = 'none';
        document.getElementById('watchProviderSearchCloseOne').style.display = 'block';
        document.getElementById("watchProviderSearchInputOne").focus();
    }

    const watchProviderSearchClose = () => {
        document.getElementById('watchProviderSearchField').style.display = 'none';
        document.getElementById('watchProviderSearch').style.display = 'block';
        document.getElementById('watchProviderSearchClose').style.display = 'none';
        document.getElementById('watchProviderSearchFieldOne').style.display = 'none';
        document.getElementById('watchProviderSearchOne').style.display = 'block';
        document.getElementById('watchProviderSearchCloseOne').style.display = 'none';
        setWatchProviderSearchQuery('')
    }


    const watchProviderSorted = (listOfWatchProviders, array) => {
        let data = listOfWatchProviders.sort((a, b) => {
            let aIndex = array.indexOf(a.provider_name);
            let bIndex = array.indexOf(b.provider_name);
            if (aIndex === -1) {
                aIndex = Number.MAX_SAFE_INTEGER;
            }
            if (bIndex === -1) {
                bIndex = Number.MAX_SAFE_INTEGER;
            }
            return aIndex - bIndex;
        });

        return data
    }

    const mountWatchProviders = (country) => {
        if (country !== '') {
            GetWatchProvidersAsync(country).then(resp => {
                let listOfWatchProviders = resp.data.results

                listOfWatchProviders = listOfWatchProviders.map((x) => {
                    return {
                        provider_id: x['provider_id'],
                        provider_name: x.provider_name.replace(' Plus', '+')
                    }
                })

                if (country === 'BR') {
                    listOfWatchProviders = watchProviderSorted(listOfWatchProviders, BR)
                }
                if (country === 'AU') {
                    listOfWatchProviders = watchProviderSorted(listOfWatchProviders, AU)

                }
                if (country === 'GB') {
                    listOfWatchProviders = watchProviderSorted(listOfWatchProviders, GB)

                }
                if (country === 'US') {
                    listOfWatchProviders = watchProviderSorted(listOfWatchProviders, US)

                }
                // setUserCountry(country)
                setWatchProviders(listOfWatchProviders)
            })
        }
    }

    useEffect(() => {
        GetCategoriesMovieAsync().then(resp => {
            setMovieCategories(resp.data.genres)
        })

        GetCategoriesTvAsync().then(resp => {
            setSeriesCategories(resp.data.genres)
        })

        let selectedCountry = localStorage.getItem('country')

        console.log(selectedCountry)

        if (selectedCountry) {
            setCountry(selectedCountry)
            setUserCountry(selectedCountry)
            mountWatchProviders(selectedCountry)
            return
        }
        
        axios.get(`https://ipinfo.io/json?token=87a00ccd239ab8`)
            .then(response => {
                countryForApi = response.data.country
                if (response.data.country === 'GB' || response.data.country === 'AU' || response.data.country === 'CA') {
                    setCountry(response.data.country)
                    setUserCountry(response.data.country)
                } else {
                    setCountry('US')
                    setUserCountry('US')
                }
                mountWatchProviders(response.data.country)
            })
            .catch(e => {
                console.log(e);
            });
    }, [])

    useEffect(() => {
        
        (async () => {
            setUserCountry(country)
            mountWatchProviders(country) 
        })
            ();
    }, [country])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!closeSideBar?.current?.contains(event.target)) {
                setSideBarActive(false);
            }
            // setDropdown(false)
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [closeSideBar]);

    useEffect(() => {
        if (sideBarActive) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'scroll'
        }
    }, [sideBarActive])

    return (
        <section className='navbar_section'>
            <NavBar
                clearFiltersAndSearch={clearFiltersAndSearch}
                setSideBarActive={setSideBarActive}
                country={country}
                selectCountry={selectCountry}
                inputRef={inputRef}
                search={search}
                setSearch={setSearch}
                userDetails={userDetails}
                setIsAuthenticated={setIsAuthenticated}
                isAuthenticated={isAuthenticated}
                GetSearchQuery={GetSearchQuery}
            />
            
            <Filters
                movieGenreFilter={movieGenreFilter}
                setMovieGenreFilter={setMovieGenreFilter}
                seriesGenreFilter={seriesGenreFilter}
                setSeriesGenreFilter={setSeriesGenreFilter}
                streamingFilter={streamingFilter}
                setStreamingFilter={setStreamingFilter}
                setSideBarActive={setSideBarActive}
                movieCategories={movieCategories}
                seriesCategories={seriesCategories}
                genreFilterSelected={genreFilterSelected}
                watchProviderSelected={watchProviderSelected}
                watchProviders={watchProviders}
                watchProviderSearch={watchProviderSearch}
                watchProviderSearchClose={watchProviderSearchClose}
                setWatchProviderSearchQuery={setWatchProviderSearchQuery}
                watchProviderSearchQuery={watchProviderSearchQuery}
                selectAllStreaming={selectAllStreaming}
                streamingFilterSelected={streamingFilterSelected}
                categorySelected={categorySelected}
                setSelectedCategory={setSelectedCategory}
                setSelectedWatchProvider={setSelectedWatchProvider}
            />

            <SideNav
                sideBarActive={sideBarActive}
                setSideBarActive={setSideBarActive}
                closeSideBar={closeSideBar}
                setSelectedCategory={setSelectedCategory}
                setSelectedWatchProvider={setSelectedWatchProvider}
                movieGenreFilter={movieGenreFilter}
                setMovieGenreFilter={setMovieGenreFilter}
                seriesGenreFilter={seriesGenreFilter}
                setSeriesGenreFilter={setSeriesGenreFilter}
                setStreamingFilter={setStreamingFilter}
                movieCategories={movieCategories}
                genreFilterSelected={genreFilterSelected}
                seriesCategories={seriesCategories}
                streamingFilter={streamingFilter}
                watchProviderSearch={watchProviderSearch}
                watchProviderSearchClose={watchProviderSearchClose}
                watchProviderSearchQuery={watchProviderSearchQuery}
                setWatchProviderSearchQuery={setWatchProviderSearchQuery}
                watchProviders={watchProviders}
                selectAllStreaming={selectAllStreaming}
                streamingFilterSelected={streamingFilterSelected}
                categorySelected={categorySelected}
                watchProviderSelected={watchProviderSelected}
            />
           
        </section>

    )
}

export default Navbar