import React from 'react'
import WatchProviderLink from './WatchProviderLink';
import OtherCountries from './OthersCountries';

function WatchProvidersDesktop({ details }) {

    //console.log(details?.buy_rent_provider)
    let existsBuyOrRent = details?.buy_rent_provider ? details?.buy_rent_provider.length > 0 : false
    // console.log(existsBuyOrRent)

    return (
        <div className='row'>
            {details?.watch?.length !== 0 &&
                <div className='col-md-6'>
                    {details?.watch?.length !== 0 && <h6 className='text-white watch_here_label'>Stream or sign up here</h6>}
                    <div className='watch_provider_slider'>
                        {details.watch && details?.watch?.map((x, i) => (
                            x?.provider_name !== 'HBO Max' &&
                                <WatchProviderLink key={i} providerName={x?.provider_name} title={details.original_title} logoPath={x?.logo_path} detail={details} />
                        ))}
                    </div>
                </div>
            }
            {details?.buy_rent &&
                <div className='col-md-6'>
                    {details?.buy_rent && <h6 className='text-white watch_here_label'>Buy or Rent here</h6>}
                    <div className='watch_provider_slider mb-3'>
                        {details?.buy_rent?.map((x, i) => (
                            x?.provider_name !== 'HBO Max' &&
                                <WatchProviderLink key={i} providerName={x?.provider_name} title={details.original_title} logoPath={x?.logo_path} detail={details} />
                                
                        ))}
                    </div>                    
                </div>
            }

            {(details?.watch?.length === 0 && !existsBuyOrRent) && <div className='col-md-6'>
                    <h6 className='text-white watch_here_label'>Not available to stream in your location</h6>
                    {details?.provider_path && Object.keys(details?.provider_path).length>0 && <OtherCountries details={details} />}
                </div>
                
            }
        </div>
    )
}

export default WatchProvidersDesktop
