import React, { useEffect, useState } from 'react'
import { getWatchList, deleteWatchList } from '../APIS/apis'
import { Link, Navigate } from 'react-router-dom'
import { MdDelete } from 'react-icons/md';
import { ThreeCircles } from 'react-loader-spinner'
import { getCookie } from '../Services/TokenFunction';
import jwt_decode from "jwt-decode";


function Watchlist() {

    const [list, setList] = useState([])
    const [loader, setLoader] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [userDetails, setUserDetails] = useState('')
    const [isReady, setIsReady] = useState(false);


    const removeWatchList = async (id, type) => {
        setLoader(true)
        const watchList = await deleteWatchList(id, type, userDetails.email)
        setList(list.filter((x) => x.id !== id))
        setLoader(false)
    }

    useEffect(() => {
        if (isAuthenticated) {
            const token = getCookie('token')
            if (token) {
                var decoded = jwt_decode(token);
                setUserDetails(decoded)
            }
            setIsReady(true)
        }
    }, [isAuthenticated])

    useEffect(() => {
        const token = getCookie('token')
        if (token) {
            var decoded = jwt_decode(token);
            setUserDetails(decoded)
            setIsAuthenticated(true)
        }
        setIsReady(true)
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        (async () => {
            window.scrollTo(0, 0)
            setLoader(true)
            const watchList = await getWatchList(userDetails.email)
            setList(watchList)
            setLoader(false)
        })
            ();
    }, [userDetails])

    if (!isReady) {
        return null;
    } else {
        if (userDetails) {
            return (
                <div className='container-fluid mt-5'>
                    <div className='row watchlist_section pt-5'>
                        <div className='col-md-12'>
                            <h2 className='text-white'>Your WatchList</h2>
                        </div>
                        {list && list.map((x, j) => {
                            if (x.type === 'movie') {
                                return <div className='col-md-2 col-4'><Link to={`/find-movies/${x.id}/${x.title?.replace(/\s+/g, '-')}`} className="nav-link" key={j} state={{ id: `${x.id}`, type: `${x.type}` }}><div className='slide_card' ><img className='w-100' src={`https://image.tmdb.org/t/p/w500/${x.poster_path}`} alt="" /></div></Link><MdDelete style={{ fontSize: '20px' }} className='text-white float-end mt-1 mb-3 delete_icon' onClick={() => removeWatchList(x.id, x.type)} /> </div>
                            } else {
                                return <div className='col-md-2 col-4'><Link to={`/find-series/${x.id}/${x.title?.replace(/\s+/g, '-')}`} className="nav-link" key={j} state={{ id: `${x.id}`, type: `${x.type}` }}><div className='slide_card' ><img className='w-100' src={`https://image.tmdb.org/t/p/w500/${x.poster_path}`} alt="" /></div></Link><MdDelete style={{ fontSize: '20px' }} className='text-white float-end mt-1 mb-3 delete_icon' onClick={() => removeWatchList(x.id, x.type)} /> </div>
                            }
                        })}
                        {list && list.length === 0 && !loader && <h5 className='text-white'>You haven't added anything to your WatchList yet</h5>}
                    </div>
                    <ThreeCircles
                        height="100"
                        width="100"
                        color="#ffe01b"
                        wrapperStyle={{ margin: 'auto' }}
                        wrapperClass="loader"
                        visible={loader}
                        ariaLabel="three-circles-rotating"
                        outerCircleColor=""
                        innerCircleColor=""
                        middleCircleColor=""
                    />
                </div>
            )
        } else {
            return <Navigate replace to="/" />;
        }
    }
}

export default Watchlist