import React, { useEffect } from 'react'

function Cookies() {

  const setDocumentDescription = (description) => {
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute('content', description);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', 'description');
      newMetaTag.setAttribute('content', description);
      document.head.appendChild(newMetaTag);
    }
  };

  useEffect(() => {
    document.title = "StreamDip | Cookies";
    setDocumentDescription('StreamDip lets you search for Movies and Series across all Streaming Services in one place! Search Netflix, Apple TV+, Disney+, Prime Video, HBO Max and more on StreamDip');
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='container pt-5'>
      <h1 className='pt-4 text-white terms_header'>Cookies</h1>
      <p className='text-white mt-5'>When you visit our website and use our services, we may collect certain information by automated
        means, such as cookies. A &quot;cookie&quot; is a text file that websites send to a visitor&#39;s computer or other
        Internet-connected device to uniquely identify the visitor&#39;s browser or to store information or
        settings in the browser.</p>
      <p className='text-white mt-5'>We may use third-party web analytics services on StreamDip, such as Google Analytics. These
        service providers help us analyze how users use StreamDip. The information collected for this
        purpose (including your IP address and other information collected by automated means) will be
        disclosed to or collected directly by these service providers. These service providers may retain and
        use anonymised, aggregated data collected from users of StreamDip in connection with their own
        businesses, including in order to improve their products and services. To learn more about opting
        out of data collection through Google Analytics, click the link below: at <a className='text-white' href="https://www.google.com/intl/en/policies/privacy/partners">https://www.google.com/intl/en/policies/privacy/partners</a>.</p>
      <p className='text-white mt-5'>We use information collected online through cookies and other automated means for purposes such
        as (i) recognizing your computer when you visit StreamDip, (ii) tracking you as you navigate Stream
        Dip (iii) improving StreamDip’s usability, (iv) analyzing use of StreamDip, (v) managing StreamDip,
        and (vi) personalizing StreamDip. We also use this information to help diagnose technical and
        service problems, administer StreamDip, identify users of StreamDip, and gather demographic
        information about our users. We use clickstream data to determine how much time users spend on
        web pages of StreamDip, how users navigate through StreamDip, and how we may tailor Stream
        Dip to better meet the needs of our users.</p>
      <p className='text-white mt-5'>
        Your browser may tell you how to be notified when you receive certain types of cookies or how to
        restrict or disable certain types of cookies. Please note, however, that without cookies you may not
        be able to use all of the features of StreamDip.
      </p>
      <p className='text-white mt-5'>
        We also may use the information we obtain about you in other ways for which we provide specific
        notice at the time of collection.
      </p>
    </div>
  )
}

export default Cookies