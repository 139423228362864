import React from 'react'
import { BsFillPersonFill } from 'react-icons/bs';

function CastSlider({ list }) {

  const backDropPath = 'https://image.tmdb.org/t/p/w500'

  return (        
    <section className="cast_section">
        <div className="container-fuild">
            <div className="row cast_row">
                <div className="col-md-12">
                    <h2 className='text-white'>Cast & Crew</h2>
                    <div className="cast_wrapper">
                        <div className='cast_parent'>
                            {list.map((x, i) => (
                                <div className='cast_child' key={i}>
                                    <div className="card">
                                        {x.profile_path ? <img src={`${backDropPath}${x.profile_path}`} className="card-img-top" alt="..." /> : <div className='placeholder_div'> <img src='/placeholder.png' className="card-img-top placeholder_cast" alt="..." /> <BsFillPersonFill className='person' /> </div>}
                                        <div className="card-body">
                                            <p className="card-title text-white"><strong>{x.name}</strong></p>
                                            <p className="card-text">{x.character}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default CastSlider
