export const setCookie = (name,value,exp_days) => {
    var expireDate = (new Date(Date.now()+ exp_days * 24 * 60 * 60 * 1000)).toUTCString();
    var expires = "expires=" + expireDate;
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export const getCookie = (name) => {
    var cname = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++){
        var c = ca[i];
        while(c.charAt(0) === ' '){
            c = c.substring(1);
        }
        if(c.indexOf(cname) === 0){
            return c.substring(cname.length, c.length);
        }
    }
    return "";
}

export const deleteCookie = (name) => {
    var d = new Date();
    d.setTime(d.getTime() - (60*60*1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = name + "=;" + expires + ";path=/";
}