import React, { useState } from 'react'
import { ThreeCircles } from 'react-loader-spinner'
import SeasonsSlider from './components/SeasonsSlider'


function Seasons({ list, id ,title, type, setSelectedSeason}) {
    const [loader] = useState(false)    

    return (
        <>            
            <SeasonsSlider list={list} id={id} title={title} type={type} setSelectedSeason={setSelectedSeason}/>
            <ThreeCircles
                height="100"
                width="100"
                color="#ffe01b"
                wrapperStyle={{ margin: 'auto' }}
                wrapperClass="loader"
                visible={loader}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
            />
        </>
    )
}


export default Seasons