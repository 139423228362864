import {createContext, useState} from 'react';
import jwtDecode from 'jwt-decode';
const UserContext = createContext({});

function UserProvider({children}) {
    const [countryCode, setCountryCode] = useState('US');

    return <UserContext.Provider
        value={{
            countryCode,
            setCountryCode
        }}>
        {children}
    </UserContext.Provider>
}

export {UserProvider, UserContext};