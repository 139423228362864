import React, { useRef } from 'react'

import { IoApps } from "react-icons/io5";

import { BiMovie } from 'react-icons/bi';
import { RiComputerLine } from 'react-icons/ri';
import { AiOutlineSearch } from 'react-icons/ai';
import { AiFillCloseCircle } from 'react-icons/ai';

export default function SideNav(
    {
        sideBarActive,
        setSideBarActive,
        closeSideBar,
        setSelectedCategory,
        setSelectedWatchProvider,
        movieGenreFilter,
        setMovieGenreFilter,
        seriesGenreFilter,
        setSeriesGenreFilter,
        setStreamingFilter,
        movieCategories,
        genreFilterSelected,
        seriesCategories,
        streamingFilter,
        watchProviderSearch,
        watchProviderSearchClose,
        watchProviderSearchQuery,
        setWatchProviderSearchQuery,
        watchProviders,
        selectAllStreaming,
        streamingFilterSelected,
        categorySelected,
        watchProviderSelected,
    }
) {

    const moviesDropdownRef = useRef();
    const seriesDropdownRef = useRef();
    const streamingDropdownRef = useRef();

    return <div id="mySidenav" className="sidenav_parent" style={{ width: sideBarActive ? '100%' : '0px' }}>
                <div className="sidenav" ref={closeSideBar} style={{ width: sideBarActive ? '325px' : '0px', marginLeft: "auto" }}>
                    <div className='position-relative vh-100'>
                        <div className='sidenav_header'>
                            <div className='row'>
                                <div className='col-md-6 col-6'>
                                    <h3 className='side_nav_title'>Search</h3>
                                </div>
                                <div className='col-md-6 col-6'>
                                    <p className='text-end sidenav_clear_filter mb-0 text-decoration-underline'>
                                        <span onClick={() => { setSelectedCategory([]); setSelectedWatchProvider([]); setMovieGenreFilter([]); setSeriesGenreFilter([]); setStreamingFilter([]); seriesDropdownRef.current.classList.remove('show'); moviesDropdownRef.current.classList.remove('show'); streamingDropdownRef.current.classList.remove('show'); }}>Clear All
                                            {/* <i class="fa fa-times ms-2" aria-hidden="true" onClick={() => setSideBarActive(false)}></i> */}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='filter_section_sidenav' style={{ padding: sideBarActive ? '0px 10px' : '0px' }}>
                            <div className="btn-group w-100">
                                <button className={`btn btn-secondary dropdown-toggle w-100 ${movieGenreFilter.some(item => item.type === 'movie') ? 'show active' : ''}`} type="button" id="five" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                    <BiMovie style={{ fontSize: '20px' }} /> Movies
                                </button>
                                <ul className="dropdown-menu w-100" ref={moviesDropdownRef} aria-labelledby="five">
                                    <li>
                                        <ul className='inner_list mt-3 mx-3'>
                                            <>
                                                {movieCategories && movieCategories.map((x, i) => (
                                                    <li key={i}>
                                                        <div className="form-check me-2 ps-0 mb-3 position-relative">
                                                            <input className="form-check-input" type="checkbox" value={x.id} id="flexCheckDefault" onChange={() => genreFilterSelected(x.id, 'movie')} />
                                                            <label className={`form-check-label ${movieGenreFilter.some(item => item.genre === x.id) ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                                {x.name}
                                                            </label>
                                                        </div>
                                                    </li>
                                                ))}
                                                <li>
                                                    <div className="form-check me-2 ps-0 mb-1 position-relative">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" onChange={() => genreFilterSelected('trending', 'movie')} />
                                                        <label className={`form-check-label ${movieGenreFilter.some(item => item.genre === 'trending') ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                            Trending
                                                        </label>
                                                    </div>
                                                </li>
                                            </>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="btn-group w-100">
                                <button className={`btn btn-secondary dropdown-toggle w-100 ${seriesGenreFilter.some(item => item.type === 'tv') ? 'show active' : ''}`} type="button" id="six" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                    <RiComputerLine style={{ fontSize: '20px' }} /> Series
                                </button>
                                <ul className="dropdown-menu w-100" ref={seriesDropdownRef} aria-labelledby="six">
                                    <li>
                                        <ul className='inner_list mt-3 mx-3'>
                                            <>
                                                {seriesCategories && seriesCategories.map((x, i) => (
                                                    <li key={i}>
                                                        <div className="form-check me-2 ps-0 mb-3 position-relative">
                                                            <input className="form-check-input" type="checkbox" value={x.id} id="flexCheckDefault" onChange={() => genreFilterSelected(x.id, 'tv')} />
                                                            <label className={`form-check-label ${seriesGenreFilter.some(item => item.genre === x.id) ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                                {x.name}
                                                            </label>
                                                        </div>
                                                    </li>
                                                ))}
                                                <li>
                                                    <div className="form-check me-2 ps-0 mb-1 position-relative">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" onChange={() => genreFilterSelected('trending', 'tv')} />
                                                        <label className={`form-check-label ${seriesGenreFilter.some(item => item.genre === 'trending') ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                            Trending
                                                        </label>
                                                    </div>
                                                </li>
                                            </>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="btn-group w-100 streaming_service_mobile">
                                <button className={` btn btn-secondary dropdown-toggle w-100 ${streamingFilter.length > 0 ? 'show active' : ''}`} type="button" id="seven" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                <IoApps style={{ fontSize: '20px' }} />  Streaming Services
                                </button>
                                <ul className="dropdown-menu w-100" ref={streamingDropdownRef} aria-labelledby="seven">
                                    <li className='watchprovider_search_wrapper'>
                                        <AiOutlineSearch className='watchprovider_search' id='watchProviderSearchOne' style={{ fontSize: '20px' }} onClick={() => watchProviderSearch()} />
                                        <AiFillCloseCircle className='watchprovider_search_close' id='watchProviderSearchCloseOne' style={{ fontSize: '20px' }} onClick={() => { watchProviderSearchClose(); setWatchProviderSearchQuery('') }} />
                                        {/* <i className="fa fa-times "  aria-hidden="true" ></i> */}
                                        {/* <i className="fa fa-search watchprovider_search" id="watchProviderSearchOne" aria-hidden="true" onClick={() => watchProviderSearch()}></i> */}
                                        <div className="input-group mb-3 watchprovider_search_field" id='watchProviderSearchFieldOne'>
                                            <input type="text" className="form-control" id='watchProviderSearchInputOne' value={watchProviderSearchQuery} placeholder="Search ..." aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => setWatchProviderSearchQuery(e.target.value)} />
                                            <span className="input-group-text" id="basic-addon1">
                                                <AiOutlineSearch style={{ fontSize: '20px' }} />
                                                {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <ul className='inner_list mt-3 mx-3'>
                                            <>
                                                {watchProviderSearchQuery === '' && watchProviders.length > 0 &&
                                                    <li>
                                                        <div className="form-check me-2 ps-0 mb-1 position-relative">
                                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" onClick={() => selectAllStreaming()} />
                                                            <label className={`form-check-label ${streamingFilter.length === watchProviders.length ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                                All
                                                            </label>
                                                        </div>
                                                    </li>                                                    
                                                }
                                                {watchProviders && watchProviders.filter((x) => {
                                                    if (watchProviderSearchQuery == '') {
                                                        return x
                                                    } else if (x.provider_name.toLowerCase().includes(watchProviderSearchQuery.toLowerCase())) {
                                                        return x
                                                    }
                                                }).map((x, i) => (                                                    
                                                        x.provider_name !== 'Apple TV' && x.provider_name !== 'HBO Max' &&
                                                            <li key={i}>
                                                                <div className="form-check me-2 ps-0 mb-3 position-relative">
                                                                    <input className="form-check-input" type="checkbox" value={x.provider_id} id="flexCheckDefault" onChange={() => streamingFilterSelected(x.provider_name, x.provider_id)} />
                                                                    <label className={`form-check-label ${streamingFilter.some(item => item.id === x.provider_id) ? 'active' : ''}`} htmlFor="flexCheckDefault">
                                                                        {x.provider_name}
                                                                    </label>
                                                                </div>
                                                            </li>                                                                                                                
                                                ))}
                                            </>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='sidenav_footer' style={{ padding: sideBarActive ? '0px 10px' : '0px', bottom: "100px" }}>
                            <button className='btn btn_sidenav_result rounded-pill' onClick={() => { categorySelected(movieGenreFilter, seriesGenreFilter); watchProviderSelected(streamingFilter); setSideBarActive(false) }}>Show Results</button>
                        </div>
                    </div>
                </div>
            </div>
}