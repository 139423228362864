import React, { useEffect } from 'react'

function Developer() {

  const setDocumentDescription = (description) => {
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute('content', description);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', 'description');
      newMetaTag.setAttribute('content', description);
      document.head.appendChild(newMetaTag);
    }
  };

  useEffect(() => {
    document.title = "StreamDip | Developer Info";
    setDocumentDescription('StreamDip lets you search for Movies and Series across all Streaming Services in one place! Search Netflix, Apple TV+, Disney+, Prime Video, HBO Max and more on StreamDip');
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='container pt-5'>
      <h1 className='text-white pt-4 terms_header'>Developer Information</h1>
      <p className='text-white mt-5'>Information about developer</p>
      <p className='text-white'>The developer account of this website and mobile apps is Danilo Cunha(danilobc@gmail.com).</p>
    </div>
  )
}

export default Developer