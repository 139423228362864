import React from 'react'
import Slider from "react-slick";
import '../../SlickSlider/SlickSlider.css'
import { BiMovie } from 'react-icons/bi';

function EpisodesSlider({ episodes, showMore, addToShowMore, episodeSliderRef }) {

  const backDropPath = 'https://image.tmdb.org/t/p/w200'

  const episodeSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 3,
    arrow: true,
    //lazy: true,
    centerPadding: '40px',
    variableWidth: false,
    adaptiveHeight: true,    
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,          
          dots: true
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,         
          infinite: false,
          // dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          //initialSlide: 3,
          arrow: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrow: false,
        }
      }
    ]
  };

  return (        
    <>      
      {episodes && episodes.length>0 && <section className='episodes_slider'>
          <div className='container-fluid'>
              <div className='row similar_movies_row'>
                  <div className='col-md-12'>
                      <h2 className='text-white'>{episodes.length  + ' ' + (episodes?.length === 1 ? 'Episode' : 'Episodes')}</h2>
                      <span className=''>
                        <Slider ref={episodeSliderRef} {...episodeSettings}>
                                  {episodes?.map((x, i) => {
                                          return <div className="episode_wrapper">                                          
                                            <div className={`card`}>
                                              {/* <div className='text-white'>Hello</div> */}
                                              <div                                                 
                                                className={`nav-link`} key={i}><div className='slide_card' >
                                                  {
                                                  x.still_path ?
                                                    <img className={`w-100`} src={`${backDropPath}${x.still_path}`} alt={x.episode_number} />
                                                  :
                                                  <div className='placeholder_div'>
                                                    <img src='/placeholder.png' className="card-img-top placeholder_cast" alt="..." />
                                                    <BiMovie className='person' />
                                                  </div>
                                                  }
                                                  </div>
                                              </div>
                                              <div className="card-body">
                                                  <p className="card-title text-white h6"><strong>{x.episode_number}. {x.name}</strong></p>
                                                  <div className="episode_overview text-white">                                                    
                                                    {!showMore.includes(x.episode_number) ?
                                                    <>
                                                    {x.overview?.slice(0, 50)}
                                                    {x.overview.length>50 ? <>...<br/><div className='show-more' onClick={() => addToShowMore(x)}>Show more</div></>: ''}
                                                    </>
                                                    :  x.overview}
                                                  </div>                                             
                                              </div>
                                            </div>
                                          </div>                                                    
                                  })}
                              </Slider>
                      </span>
                  </div>
              </div>
          </div>
        </section>}
    </>
  )
}

export default EpisodesSlider
