import axios from 'axios';

//import { REACT_APP_DEV_URL, REACT_APP_PROD_URL } from "@env"
//const MY_URL = process.env.NODE_ENV === 'development' ? REACT_APP_DEV_URL : REACT_APP_PROD_URL;
const SD_API = process.env.REACT_APP_API_URL || '';

const headers = {accept: 'application/json'};

export const getHome = async () => {
    return axios.get(`${SD_API}/api/home`);
};