import React, { useEffect } from 'react'
import { Navigate } from "react-router-dom";
import AdminSideBar from './AdminSideBar/AdminSideBar';


function AdminPanel({ userDetails, setIsAdminAuthenticated }) {

  useEffect(() => {
    console.log(userDetails)
  }, [userDetails])


  if (setIsAdminAuthenticated) {
    return (
      <>
        <AdminSideBar />
        <div className='bg-white min-vh-100'>AdminPanel</div>
      </>
    )
  } else {
    return <Navigate replace to="/" />;
  }
}

export default AdminPanel