import React, { useState, useRef } from 'react';
import './Signin.css'
import { AiOutlineClose } from 'react-icons/ai';
import { Login } from '../APIS/apis'
import { setCookie } from '../Services/TokenFunction';
import { ThreeCircles } from 'react-loader-spinner'


function Signin({ setIsAuthenticated }) {

    const [loginCredentials, setloginCredentials] = useState({
        email: '',
        password: '',
    })
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState('')
    const closeRef = useRef();

    const loginHandler = (e) => {
        setMessage('')
        setloginCredentials({ ...loginCredentials, [e.target.name]: e.target.value })
    }

    function handleInvalid() {
        setMessage('Please enter a valid email address');
    }

    const userLogin = async (event) => {
        event.preventDefault()
        setLoader(true)
        setMessage('')
        if (loginCredentials.email === '') {
            setMessage('Please enter email')
            setLoader(false)
        } else if (!loginCredentials.email.includes('@')) {
            setMessage('Please enter valid email')
            setLoader(false)
        } else if (loginCredentials.password === '') {
            setMessage('Please enter password')
            setLoader(false)
        } else {
            let signin = await Login(loginCredentials)
            setMessage(signin.message)
            setLoader(false)
            if (signin.token) {
                setIsAuthenticated(true)
                setCookie('token', signin.token, 7)
                setCookie('refreshToken', signin.refreshToken, 30)
                setTimeout(() => {
                    closeRef.current.click()
                    setMessage('')
                }, 1000);
            }
        }
    }


    return (
        <>
            <div className="modal fade" id="signin" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content email__password__signin pb-0">
                        <div className="modal-header">
                            <h5 className="modal-title text-white" id="exampleModalLabel">Sign In</h5>
                            <button className='btn' data-bs-dismiss="modal" ref={closeRef}><AiOutlineClose className='text-white' /></button>
                        </div>
                        <div className="modal-body">
                            <div className="">
                                <form onSubmit={(e) => userLogin(e)} noValidate>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label text-white">Email</label>
                                        <input type="email" autoComplete="email" className="form-control mt-0" name="email" placeholder="Email" aria-describedby="emailHelp" onInvalid={handleInvalid} onChange={(e) => { loginHandler(e); setMessage('') }} />
                                    </div>
                                    <div>
                                        <label htmlFor="exampleInputPassword1" className="form-label text-white">Password</label>
                                        <input type="password" autoComplete="current-passwrod" className="form-control my-0" name="password" id="passwordField" placeholder="Password" autoComplete='new-password' onChange={(e) => { loginHandler(e); setMessage('') }} />
                                    </div>
                                    <div className='login_message'>
                                        {message === '' ? '' : message === 'You have been logged in successfully' ? <p className='form-text text-success'>{message}</p> : <p className='form-text text-danger'>{message}</p>}
                                    </div>
                                    <div className='text-end mt-4'>
                                        <button className="btn btn-cancel signup__card" type="button" data-bs-toggle="modal" data-bs-target="#signup">Sign Up</button>
                                        <button type="submit" disabled={loader} className="btn btn-next position-relative">
                                            {!loader ? 'Login' :
                                                <ThreeCircles
                                                    height="20"
                                                    width="20"
                                                    color="#000"
                                                    wrapperStyle={{ margin: 'auto' }}
                                                    wrapperClass="loader"
                                                    visible={loader}
                                                    ariaLabel="three-circles-rotating"
                                                    outerCircleColor=""
                                                    innerCircleColor=""
                                                    middleCircleColor=""
                                                />}
                                        </button>
                                    </div>
                                    <p className='text-center form-text forgot_password_link mt-5' data-bs-toggle="modal" data-bs-target="#forgotPassword">Forgot Password?</p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signin