import React from 'react'
import style from './AdminSideBar.module.css'
import { Link } from 'react-router-dom'

function AdminSideBar() {
    return (
        <div className={`${style.sidebar}`}>
            <Link to={'admin-home'}>Home</Link>
            <Link to={'list-users'}>User</Link>
            <Link to={'edit-privacy-policy'} >Privacy Policy</Link>
            <Link to={'edit-cookies'}>Cookies</Link>
            <Link to={'edit-about'}>About</Link>
        </div>
    )
}

export default AdminSideBar