import { useState } from 'react';
import { BiWorld } from "react-icons/bi";
import { Button, Modal, Row, Col } from 'react-bootstrap';
import './OtherCountries.css'
import { countries, lookup } from 'country-data'
import WatchProviderLink from './WatchProviderLink';
import { ProviderPathHandler } from '../../APIS/apis';

function OtherCountries({ details }) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const countries = Object.keys(details?.provider_path);

    const renderProviders = (provider_path, key) => {
        let providers = ProviderPathHandler(provider_path, key)
        
        return (
            <div>
                {providers?.watch_providers?.map((provider, index)  => {
                    return <div>
                        {index ===0 && <h6 className='text-white watch_here_label'>Stream or sign up here</h6>}
                        <WatchProviderLink key={provider.provider_id} providerName={provider?.provider_name} title={details.original_title} logoPath={provider?.logo_path} detail={details} />
                    </div>
                })}

                {providers?.buy_rent_provider && <h6 className='text-white watch_here_label mt-3'>Buy or Rent here</h6>}
                {providers?.buy_rent_provider?.map((provider, index) => {
                    return <WatchProviderLink key={provider.provider_id} providerName={provider?.provider_name} title={details.original_title} logoPath={provider?.logo_path} detail={details} />
                })}
            </div>
        )
    }

    return (
        <div className='row text-white'>
            <Button variant="primary" onClick={handleShow} >
                <BiWorld size={25} /> See others countries
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton closeVariant='white'>
                <Modal.Title>Other Countries</Modal.Title>
                </Modal.Header>
                    <Modal.Body>                    
                        {countries?.map((key) => {
                            console.log(`${key}: ${details?.provider_path[key]}`);
                            let country = lookup.countries({alpha2: key})[0];
                            return <Row key={key} className="border mb-2 p-2 rounded">
                                <Col sm={2} className='text-center'>
                                    <span className="emoji">{country.emoji}</span>
                                    <span className='text-white'>{country.name.split(',')[0]}</span>
                                </Col>
                                <Col className='d-flex flex-row'>
                                    {renderProviders(details?.provider_path, key)}
                                </Col>
                            </Row>
                        })}
                    </Modal.Body>
                <Modal.Footer>                    
                    <Button variant="primary" onClick={handleClose}>
                        Close                        
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default OtherCountries
