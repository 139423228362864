import { AiOutlineClose } from 'react-icons/ai';
import React, { useState, useRef, useEffect } from 'react'
import { updateProfile } from '../APIS/apis'
import { setCookie, getCookie } from '../Services/TokenFunction'
import jwt_decode from "jwt-decode";

function Profile({ userDetails, setUserDetails }) {
    const [profileFields, setProfileFields] = useState({
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: userDetails.email
    })

    const closeRef = useRef();

    const handleChange = (e) => {
        setProfileFields({ ...profileFields, [e.target.name]: e.target.value })
    }

    const editProfile = async (event) => {
        event.preventDefault()
        const result = await updateProfile(profileFields.email, profileFields.first_name, profileFields.last_name)
        if (result.token) {
            setCookie('token', result.token, 30)
            const token = getCookie('token')
            if (token) {
                var decoded = jwt_decode(token);
                setUserDetails(decoded)
            }
            setTimeout(() => {
            closeRef.current.click()
            }, 1000);
        }
    }

    useEffect(() => {
        // setProfileFields(profileFields.first_name: userDetails.first_name)
        const profile = {...profileFields}
        profile.first_name = userDetails.first_name
        profile.last_name = userDetails.last_name
        profile.email= userDetails.email
        setProfileFields(profile)
    }, [userDetails])
    

    return (
        <div className="modal fade" id="profile" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content email__password__signin">
                    <div className="modal-header">
                        <h5 className="modal-title text-white" id="exampleModalLabel">Edit Profile</h5>
                        <button className='btn' data-bs-dismiss="modal" ref={closeRef}><AiOutlineClose className='text-white' /></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(e) => editProfile(e)}>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label text-white">First Name</label>
                                <input type="text" className="form-control mt-0" name="first_name" value={profileFields.first_name} placeholder="First Name" aria-describedby="emailHelp" onChange={(e) => handleChange(e)} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label text-white">Last Name</label>
                                <input type="text" className="form-control mt-0" name="last_name" value={profileFields.last_name} placeholder="Last Name" aria-describedby="emailHelp" onChange={(e) => handleChange(e)} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label text-white">Email</label>
                                <input autoComplete="email" type="email" className="form-control mt-0" name="email" value={profileFields.email} placeholder="Email" aria-describedby="emailHelp" disabled />
                            </div>
                            <div className='text-end mt-4'>
                                <button type="submit" className="btn btn-next">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile