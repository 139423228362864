import React, { useEffect, useState, useRef } from 'react'
import { useLocation, redirect } from 'react-router-dom'
import { LoadMoreApi } from '../APIS/apis'
import { Link, useNavigate } from 'react-router-dom'
import { ThreeCircles } from 'react-loader-spinner'
import './LoadMore.css'

function LoadMore({ loadMoreArray, setLoadMoreArray, pages, setPages, listOfCategories, setPagesNo, pagesNo }) {
    const [loader, setLoader] = useState(false)
    const location = useLocation()
    const { url = '' } = location.state || ''
    const { name = '' } = location.state || ''
    const navigate = useNavigate()

    const OnClickCLoad = async () => {
        setLoader(true)
        let num = pagesNo
        let array = await LoadMoreApi(url, num)
        setPages(array[0]?.total_pages)
        setLoadMoreArray([...loadMoreArray, array])
        num = num + 1
        setPagesNo(num)
        // listOfCategories.current++
        setLoader(false)
    }

    useEffect(() => {
        (async () => {            
            if (loadMoreArray.length === 0) {                
                setLoader(true)
                window.scrollTo(0, 0)
                let num = pagesNo
                let array = await LoadMoreApi(url, num)
                num = num + 1
                setPagesNo(num)
                setPages(array[0]?.total_pages)
                // listOfCategories.current++
                setLoader(false)
                setLoadMoreArray([...loadMoreArray, array])
            }
        })

            ();
    }, [])
    


    if (url) {
        return (
            <div className='container-fluid load_more_page'>
                <div className='row'>
                    <div className='col-md-12 mt-5'>
                        {/* <button onClick={() => { navigate(-1) }} className='btn btn_back'><i className="fa fa-angle-left" aria-hidden="true"></i> Go Back</button> */}
                    </div>
                    <div className='col-md-12 mt-4'>
                        <h1 className='text-white'>{name}</h1>
                    </div>
                    {loadMoreArray && loadMoreArray.map((x) => (
                        <>
                            {x.map((y, i) => (
                                <div className='col-md-2 my-4 col-4' key={i}>
                                    {y.type === 'movie' ?
                                        <Link to={`/find-movies/${y.id}/${y.title?.replace(/\s+/g, '-')}`} className="nav-link" state={{ id: `${y.id}`, type: `${y.type}` }}>
                                            <img src={`https://image.tmdb.org/t/p/w300${y.poster_path}`} width='100%' alt="" />
                                        </Link>
                                        :
                                        <Link to={`/find-series/${y.id}/${y.title?.replace(/\s+/g, '-')}`} x className="nav-link" state={{ id: `${y.id}`, type: `${y.type}` }}>
                                            <img src={`https://image.tmdb.org/t/p/w300${y.poster_path}`} width='100%' alt="" />
                                        </Link>
                                    }

                                </div>
                            ))}
                        </>
                    ))}
                    {pages !== listOfCategories.current && pages > listOfCategories.current ?
                        <p className='text-center mb-4 position-relative'>
                            {!loader &&
                                <a onClick={() => OnClickCLoad()} className='fw-bold' style={{ textDecoration: 'underline', cursor: 'pointer', color: '#ffe01b', fontSize: '22px' }}>Load More</a>
                            }
                            <ThreeCircles
                                height="40"
                                width="40"
                                color="#ffe01b"
                                wrapperStyle={{ margin: 'auto' }}
                                wrapperClass="loader"
                                visible={loader}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                            />
                        </p>
                        :
                        ''
                    }
                </div>
            </div>
        )
    } else {
        return redirect("/");
    }


}

export default LoadMore