import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <section className='footer_section mt-5'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-8'>
                        <p className='mb-0 mt-3'>
                            <strong className='text-white'>Data thanks to</strong>
                            <img src="https://www.themoviedb.org/assets/2/v4/logos/v2/blue_short-8e7b30f73a4020692ccca9c88bafe5dcb6f8a62a4c6bc55cd9ba82bb2cd95f6c.svg" width='75' height='9' className='footer_logo' alt="" />
                        </p>
                    </div>
                    <div className='col-md-4'>
                        <div className='footer_links'>
                            <ul>
                                <li>
                                    <Link to={'terms-and-conditions'}>Terms & Conditions</Link>
                                </li>
                                <li>
                                    <Link to={'about-us'}>About</Link>
                                </li>
                                <li>
                                    <Link to={'cookies'}>Cookies</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Footer