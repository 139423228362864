import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import '../../SlickSlider/SlickSlider.css'

import { BiMovie } from 'react-icons/bi';

function SimilarSlider({ list, similarFunc }) {

  const backDropPath = 'https://image.tmdb.org/t/p/w500'


  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrow: true,
    centerPadding: '40px',
    responsive: [
        {
            breakpoint: 1439,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        }
    ]
  };

  return (
    <section className='similar_movies'>
        <div className='container-fluid'>
            <div className='row similar_movies_row'>
                <div className='col-md-12'>
                    <h2 className='text-white'>People also watched</h2>
                    <span className='desktop_slick_slider_parent'>
                        {list.length < 7 ? (
                            <div className='row'>
                                {list.map((x, i) => {
                                    if (x.poster_path) {
                                        if (x.type === 'movie') {
                                            return <div className='col-md-2 col-4'><Link to={`/find-movies/${x.id}/${x.title.replace(/\s+/g, '-')}`} className="nav-link" key={i} onClick={() => similarFunc(x.id, x.type)}><div className='slide_card' > {x.poster_path !== null ? <img className='w-100' src={`${backDropPath}${x.poster_path}`} alt="" /> : <div className='placeholder_div'> <img src='/placeholder.png' className="card-img-top placeholder_cast" alt="..." /> <BiMovie className='person' /></div>} </div></Link></div>
                                        } else {
                                            return <div className='col-md-2 col-4'><Link to={`/find-series/${x.id}/${x.title.replace(/\s+/g, '-')}`} className="nav-link" key={i} onClick={() => similarFunc(x.id, x.type)}><div className='slide_card' >{x.poster_path !== null ? <img className='w-100' src={`${backDropPath}${x.poster_path}`} alt="" /> : <div className='placeholder_div'> <img src='/placeholder.png' className="card-img-top placeholder_cast" alt="..." /> <BiMovie className='person' /></div>}</div></Link></div>
                                        }
                                    }
                                    return <></>

                                })}
                            </div>
                        ) : (
                            <Slider {...settings}>
                                {list.map((x, i) => {
                                    if (x.poster_path) {
                                        if (x.type === 'movie') {
                                            return <Link to={`/find-movies/${x.id}/${x.title.replace(/\s+/g, '-')}`} className="nav-link" key={i} onClick={() => similarFunc(x.id, x.type)}><div className='slide_card' >{x.poster_path !== null ? <img className='w-100' src={`${backDropPath}${x.poster_path}`} alt="" /> : <div className='placeholder_div'> <img src='/placeholder.png' className="card-img-top placeholder_cast" alt="..." /> <BiMovie className='person' /></div>}</div></Link>
                                        } else {
                                            return <Link to={`/find-series/${x.id}/${x.title.replace(/\s+/g, '-')}`} className="nav-link" key={i} onClick={() => similarFunc(x.id, x.type)}><div className='slide_card' >{x.poster_path !== null ? <img className='w-100' src={`${backDropPath}${x.poster_path}`} alt="" /> : <div className='placeholder_div'> <img src='/placeholder.png' className="card-img-top placeholder_cast" alt="..." /> <BiMovie className='person' /></div>}</div></Link>
                                        }
                                    }                                    
                                })}
                            </Slider>
                        )}
                    </span>
                    <div className='mobile_slick_slider_wrapper_parent'>
                        <div className='mobile_slick_slider_wrapper mt-3'>
                            <div className='mobile_slick_slider'>
                                {list.map((x, i) => {
                                    if (x.poster_path) {
                                        if (x.type === 'movie') {
                                            return <Link to={`/find-movies/${x.id}/${x.title.replace(/\s+/g, '-')}`} className="nav-link" key={i} onClick={() => similarFunc(x.id, x.type)}><div className='slide_card' >{x.poster_path !== null ? <img className='w-100' src={`${backDropPath}${x.poster_path}`} alt="" /> : <div className='placeholder_div'> <img src='/placeholder.png' className="card-img-top placeholder_cast" alt="..." /> <BiMovie className='person' /></div>}</div></Link>
                                        } else {
                                            return <Link to={`/find-series/${x.id}/${x.title.replace(/\s+/g, '-')}`} className="nav-link" key={i} onClick={() => similarFunc(x.id, x.type)}><div className='slide_card' >{x.poster_path !== null ? <img className='w-100' src={`${backDropPath}${x.poster_path}`} alt="" /> : <div className='placeholder_div'> <img src='/placeholder.png' className="card-img-top placeholder_cast" alt="..." /> <BiMovie className='person' /></div>}</div></Link>
                                        }
                                    }                                   
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default SimilarSlider
