import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { ThreeCircles } from 'react-loader-spinner'
import { resetPasswordFunction, resetPasswordFunctionWithCode } from '../APIS/apis'
import { Button } from 'react-bootstrap';

function PasswdReset() {

  const [email, setEmail] = useState('')
  const [loader, setLoader] = useState(false)
  const [message, setMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [resetpassword, setResetPassword] = useState({
    password: '',
    confirm_password: ''
  })
  const search = useLocation().search;
 

  const handlePassword = (e) => {
    setResetPassword({ ...resetpassword, [e.target.name]: e.target.value })
  }

  const resetPassword = async (event) => {
    event.preventDefault()
    setLoader(true)
    setMessage('')
    let regex = '^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,}$'
   
    if (!resetpassword.password.match(regex)) {
        setLoader(false)        
        setMessage('Password must have at least 8 characters with at least 1 uppercase letter, 1 lowercase letter and 1 number')
    }
    else if (resetpassword.password === resetpassword.confirm_password) {
      console.log(new URLSearchParams(search))
        let params = new URLSearchParams(search)
        var arr = Array.from(params.entries());
        console.log(arr)
        const result = await resetPasswordFunctionWithCode(params.get('code'), resetpassword.password)
        if (result.status === true) {
            setLoader(false)
            setMessage('')
            setSuccessMessage(result.message)
        } else {
            setLoader(false)
            setSuccessMessage('')
            setMessage(result.message)
        }
    } else {
        setMessage('Password and confirm password should be same.')
    }
}

  const setDocumentDescription = (description) => {
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute('content', description);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', 'description');
      newMetaTag.setAttribute('content', description);
      document.head.appendChild(newMetaTag);
    }
  };

  useEffect(() => {
    document.title = "StreamDip | Reset Password";
    setDocumentDescription('StreamDip lets you search for Movies and Series across all Streaming Services in one place! Search Netflix, Apple TV+, Disney+, Prime Video, HBO Max and more on StreamDip');

    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='container pt-5'>
      <h1 className='text-white pt-4 terms_header'>Reset Password</h1>
      <form noValidate>
        <div className="mb-3">
            <input type="password" className="form-control" name="password" id="passwordField" value={resetpassword.password} placeholder="Password" aria-describedby="emailHelp" onChange={(e) => handlePassword(e)} required />
        </div>
        <div className="mb-0">
            <input type="password" className="form-control mb-0" name="confirm_password" id="confirmPasswordField" value={resetpassword.confirm_password} placeholder="Confirm Password" aria-describedby="emailHelp" onChange={(e) => handlePassword(e)} required />
        </div>
        <div className='login_message'>
            <small className="mt-5 text-primary">
                {successMessage}
            </small>
            <small className="text-warning">
                {message}
            </small>
            <p className="text-primary">
                {message}
            </p>
        </div>
        <div className='mt-4'>
            <Button variant="primary" onClick={resetPassword} >
                Submit
            </Button>
            <button disabled={loader} type="submit" className="btn btn-next position-relative">
                {!loader &&
                    'Reset'
                }
                <ThreeCircles height="20" width="20" color="#000" wrapperStyle={{ margin: 'auto' }} wrapperClass="loader" visible={loader} ariaLabel="three-circles-rotating" outerCircleColor="" innerCircleColor="" middleCircleColor="" />
            </button>
        </div>
    </form>
    </div>
  )
}

export default PasswdReset