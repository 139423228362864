import React, { useEffect } from 'react'

function About() {

  const setDocumentDescription = (description) => {
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute('content', description);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', 'description');
      newMetaTag.setAttribute('content', description);
      document.head.appendChild(newMetaTag);
    }
  };

  useEffect(() => {
    document.title = "StreamDip | About Us";
    setDocumentDescription('StreamDip lets you search for Movies and Series across all Streaming Services in one place! Search Netflix, Apple TV+, Disney+, Prime Video, HBO Max and more on StreamDip');
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='container pt-5'>
      <h1 className='text-white pt-4 terms_header'>ABOUT US</h1>
      <p className='text-white mt-5'>StreamDip is your new favourite couch companion and one-stop-shop for all things streaming.</p>
      <p className='text-white'>Our goal is to take the headache out of finding what to watch, and where to watch it.</p>
      <p className='text-white'>On StreamDip you can search across Netflix, AppleTV+, Disney+, Amazon Prime and more – in one place! </p>
      <p className='text-white'>You can search by Movie and Series genre, and also search for your favourite actor. It’s that easy. When you’re ready to watch, you simply click (or tap) the link and it’ll take you directly to your streaming service provider. </p>
      <p className='text-white'>You can also create an account and add titles to your WatchList for later, a great way to save a library of content that you want to watch later.
        There are no fees, ads or gimmicks with StreamDip. It’s free now and it’ll be free forever.</p>
      <p className='text-white'>We hope you love it as much as we do. You can contact us via the below if you have any questions, comments, feedback - or if you want to discuss any partnership opportunities.</p>
      <p className='text-white'> We love hearing from the StreamDip community. </p>
      <p>hello@streamdip.com</p>
    </div>
  )
}

export default About